<template>
  <div class="row d-flex flex-column align-center py-5">
    <h4 class="text-center fw-bold mb-4 d-none">REGISTER</h4>
    <div class="col-lg-6 d-flex flex-column align-center" style="position: relative; z-index: 1;">
      <v-card v-if="registerbox" class="register-card" style="border-radius:7px;box-shadow: 0px 0px 8px 0px black;"
        outlined>
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-md-12 main-page">
            <div class="bg-light main-page mx-auto shadow-none p-3"
              style="background-color:#f8b2b2; width: 100%; height: auto;">
              <!-- <div class="col-12 pe-1 pt-1 d-flex justify-end" @click="closeRegisterBox()">
                  <v-icon size="small" style="color:navy; cursor:pointer">mdi-close-circle</v-icon>
                </div> -->

              <div class="d-flex align-center w-100">
                <div class="m-2 ms-0 rounded-circle lockIcon d-flex align-center justify-center">
                  <i class="bi bi-person-plus fs-4 p-4"></i>
                </div>
                <div>
                  <h4 class="m-0">{{ $t("registerContents.signUp")}}</h4>
                  <p class="m-0 f-size-12">{{ $t("loginContents.subHaed") }}</p>
                </div>
                <!-- <div class="d-flex justify-end ml-auto ms-auto" v-if="!regMsg">
                  <v-icon @click ="registerbox = !registerbox">mdi-close</v-icon>
                </div> -->
              </div>

              <v-form ref="form" class="py-3 form-data" v-model="valid" @submit.prevent="submitForm" v-if="regForm">

                <v-text-field v-model="personal.username" :rules="userName" :label="this.$t('registerContents.labels.name')" variant="outlined"
                  placeholder="Enter your Name"></v-text-field>
                <!-- <span style="color: red;">invalid Username</span><br> -->

                <v-text-field v-model="personal.email" :rules="eMail" :label="this.$t('registerContents.labels.emailId')" placeholder="johndoe@gmail.com"
                  type="email" variant="outlined"></v-text-field>
                <!-- <span style="color: red;">invalid Email</span><br> -->

                <v-text-field v-model="personal.password" :rules="passWord" :label="this.$t('registerContents.labels.password')"
                  placeholder="Enter your password" variant="outlined" required
                  :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                  @click:append-inner="visible = !visible"></v-text-field>
                <!-- <span style="color: red;">invalid password</span> -->

                <v-text-field v-model="personal.rePassword" :rules="repassWord" :label="$t('registerContents.labels.rePassword')"
                  placeholder="Enter your Re-password" variant="outlined" required
                  :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
                  @click:append-inner="visible = !visible"></v-text-field>
                <!-- <span style="color: red;">invalid Re-password</span> -->

                <div class="d-flex my-4">
                  <div class="col-9 d-flex justify-end">
                    <v-btn width="70%" :disabled="btnDisabled" type="submit"
                      style="background-color:#26225e; color:white">{{ $t("registerContents.buttonsLink.submit") }}</v-btn>
                  </div>
                  <div class="col-3 ps-3 d-flex justify-end">
                    <v-progress-circular v-if="loadRegister" :width="3" style="color:navy"
                      indeterminate></v-progress-circular>
                  </div>
                </div>
                <!-- <v-divider></v-divider> -->
                <div class="d-flex flex-row justify-center text-center">
                  <p class="m-0" style="font-weight: 600;">{{ $t("registerContents.buttonsLink.alreadyAccount") }}
                    <span style="cursor: pointer;"> &nbsp;
                    <a href="/login" style="color:navy;text-decoration:none;text-align:end;font-weight:600;">{{ $t("registerContents.buttonsLink.login") }}</a>
                  </span>
                  </p>
                  <!-- <span style="cursor: pointer;"> &nbsp;
                    <a href="/login" style="color:navy;text-decoration:none;text-align:end;font-weight:600;">Login</a>
                  </span> -->

                </div>
              </v-form>


              <div v-if="regMsg">
                <div class="d-flex justify-center align-center p-3">
                  <v-icon color="success" size="50">mdi-check-circle-outline</v-icon>
                  <h4 class="text-success ps-2 m-0"> {{ $t("registerContents.successNotify.text2") }}...</h4>
                </div>
                <div class="d-flex justify-center align-center p-3">
                  <p>{{ registerMsg }}</p>
                </div>
              </div>
                <div class="d-flex justify-content-between w-100" v-if="regMsg">
                  <v-btn text="Return To Home" to="/" style="background-color:#26225e; color:white !important;"></v-btn>
                  <v-btn text="Login" to="/login" style="background-color:#26225e; color:white !important;"></v-btn>
                </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <!-- <div v-if="loginSection">
      <loginCom></loginCom>
    </div> -->
  <div class="text-center" v-if="dataloader">
    <dataloader></dataloader>
  </div>
</template>

<script>
// import loginCom from '@/components/loginCom.vue';
import axios from 'axios';
//   import dataloader from "@/components/dataLoder.vue";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
  components: {
    // loginCom
    //   dataloader,
  },
  data() {
    return {
      dataloader: true,
      valid: false,

      registerbox: true,
      loginSection: true,
      terms: false,
      visible: false,

      regForm: true,
      regMsg: false,
      registerMsg: "",
      loadRegister: false,
      btnDisabled: false,

      personal: {
        username: "",
        email: "",
        password: "",
        rePassword: ""
      },
      data1: {
        "request_type": "user_register",
        "user_name": "admin",
        "email": "admin12553435@gmail.com",
        "password": "1234567890",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "0000",
      },


      userName: [
        v => !!v || 'Please enter your Username',
        v => (v.length >= 3) || 'Name must be greater than 3 ',
      ],

      eMail: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      passWord: [
        v => !!v || 'Please enter your Password',
        // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
        // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
        // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
      ],
      repassWord: [
        v => !!v || 'Please enter your Re-password',
        v => v === this.personal.password || 'Passwords do not match'
      ],

      // strResult: "0123456789",

      // user_reqId:"",


      registerReq: {
        "request_type": "user_register",
        "user_name": "",
        "email": "",
        "password": "",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "",
        "token": "Tc7RQaX79Hrx5CFK",
        "portal_domain": "",
      },
      portal_group_id:"",
      portal_id:"",
      registerUrl: "",
      portal_domain:"",
    };
  },
  methods: {


    signInAgain() {
      this.loginSection = true
      this.registerbox = false
      console.log(this.loginSection, this.registerbox, "Workinglogin....")
    },
    // closeRegBox() {
    //         this.loginbox = false;
    //         this.$emit('close'); // Emit the 'close' event to the parent component
    //         console.log(this.loginbox, "1111111")
    //     },


    // onClickOutside() {
    //   this.registerbox = false
    // },
    submitForm() {

      console.log(this.valid, 'oooooooooo')
      if (this.valid) {

        this.loadRegister = true
        this.btnDisabled = true

        // this.generateBookingRefId()

        console.log(this.personal, 'eeeee......')

        this.registerReq.user_name = this.personal.username
        this.registerReq.email = this.personal.email
        this.registerReq.password = this.personal.password
        this.registerReq.user_id = this.personal.email
        this.registerReq.portal_id = this.portal_id
        this.registerReq.portal_group_id = this.portal_group_id
        this.registerReq.portal_domain = this.portal_domain


        console.log(this.registerReq, 'ffff.....')

        axios.post(this.registerUrl, this.registerReq, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {

            if (response.data) {
              this.regForm = false
              this.regMsg = true
              console.log('Response:', response.data);
              console.log('Response-Msg:', response.data.register.message);
              this.registerMsg = response.data.register.message

              // this.registerbox = false
            }
          })


          .catch(error => {
            console.log(error.response.data.message, 'wejjfuoajk')
            alert(`${error.response.data.message}`)
            this.loadRegister = false
            this.btnDisabled = false
          });
      }
    },




    // getRandomChars(length) {
    //         let result = "";
    //         for (let i = 0; i < length; i++) {
    //             const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //             result += this.strResult[randomIndex];
    //         }
    //         console.log(result, "result");
    //         return result;
    //     },
    //     getRandomInt(min, max) {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     },

    //     generateBookingRefId() {
    //         const randomChars1 = this.getRandomChars(1);
    //         const randomNum = this.getRandomInt(10, 99);
    //         const randomChars2 = this.getRandomChars(1);

    //         this.registerReq.user_id = randomChars1 + randomNum + randomChars2;
    //         console.log(this.registerReq.user_id, 'this.booking_refId ')
    //     },




    closeRegisterBox() {
      this.registerbox = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
      console.log(this.registerbox, '00000')
    },



    getConfig() {
      this.dataloader = false
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData1 = JSON.parse(portalLocal)
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
        console.log(this.registerUrl, 'head...reg..1..')
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.portal_group_id = getConfigData1.payload.portal_configuration.portal_group_id;
        this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
      }
    },
  },

  created() {
    this.getConfig();
  }
}
</script>



<style scoped>


.lockIcon{
    background-color: #F8A21A;
    height: 50px;
    width: 50px;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
    padding-left: 40px;
    color: white;
  } */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

>>>.v-input__details {
  display: block !important;
}


/* .v-checkbox .v-input__details {
    display: none;
  } */

/* Login Page End */

.register-card {
  width: 85%;
}

.form-data {
  width: 100%;
}

@media only screen and (max-width: 992px) {

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}

@media screen and (max-width:480px) {
  .register-card {
    width: 80%;
  }

  .form-data {
    width: 100%;
  }
}

@media screen and (min-width:480px) and (max-width:992px) {
  .register-card {
    width: 80%;
  }

  .form-data {
    width: 500px;
  }
}
a:visited{
  color:navy !important;
}
</style>