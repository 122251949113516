<template>
  <!-- preloader -->
  <!-- <div v-if="preloader">
            <div id="loading-center">
                <div class="loader">
                    <div class="loader-outter"></div>
                    <div class="loader-inner"></div>
                </div>
            </div>
        </div> -->
  <!-- preloader-end -->

  <!-- Scroll-top -->
  <!-- <button class="scroll-top scroll-to-target" data-target="html">
            <i class="fas fa-angle-up"></i>
        </button> -->
  <!-- Scroll-top-end-->

  <!-- main-area -->
  <main>
    <!-- slider-area -->
    <section class="slider-area">
      <div class="slider-active">
        <!-- <div
          class="single-slider slider-bg d-flex flex-column"
          data-background="assets/img/images/trbg1.jpg"
        > -->
        <div
          class="single-slider slider-bg d-flex flex-column"
          :style="backgroundStyle" >
        <!-- <v-carousel
          v-model="currentSlide"
          cycle
          hide-delimiters
          :show-arrows="false"
          class="smooth-carousel"
        >
        <v-carousel-item v-for="(item, i) in carouselData" :key="i" :value="i">
          <div
            class="single-slider slider-bg d-flex flex-column"
            :style="{ backgroundImage: `url(${item.src})` }"
          > -->
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="slider-content text-center">
                  <h2 class="title" data-animation="fadeInUp" data-delay=".2s">
                    {{ $t("homePageContent.welcomeMsg.text1") }}
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".4s">
                   {{ $t("homePageContent.welcomeMsg.text2") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- flight booking -->

          <div class="booking-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="booking-tag">
                    <ul>
                      <li>
                        <a href="#" class="act"
                          ><i class="fa-solid fa-plane"></i>{{ $t("homePageContent.label.flights") }}</a
                        >
                      </li>
                      <!-- <li>
                        <a href="#"
                          ><i class="fa-solid fa-umbrella-beach"></i>Holidays</a
                        >
                      </li>
                      <li>
                        <a href="#"
                          ><i class="fa-solid fa-plane-departure"></i>Flights
                          and Stays</a
                        >
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-bed"></i>Stays</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-bus"></i>Tours</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-ship"></i>Cruises</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-tag"></i>Deals</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-car"></i>Cars</a>
                      </li> -->
                    </ul>
                  </div>
                  <div class="booking-wrap mb-3">
                    <ul
                      class="nav nav-tabs"
                      id="myTab"
                      role="tablist"
                      style="
                        background: black;
                        border-radius: 20px 20px 0px 0px;
                      "
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          @click="
                            (airbooking = true),
                              (mytrips = false),
                              (resentSearch = false)
                          "
                          :class="airbooking ? 'true-btn' : 'false-btn'"
                          style="border-top-left-radius: 20px"
                        >
                          <i class="fa-solid fa-plane me-1"></i> {{ $t("homePageContent.label.airBooking") }}
                        </button>
                      </li>
                      <li class="nav-item" role="presentation"></li>
                      <li class="nav-item" role="presentation">
                        <button
                          @click="
                            (airbooking = false),
                              (mytrips = true),
                              (resentSearch = false)
                          "
                          :class="mytrips ? 'true-btn' : 'false-btn'"
                        >
                          <i class="fa-regular fa-file-lines me-1"></i>
                          {{ $t("homePageContent.label.myTrips") }}
                        </button>
                      </li>

                      <li class="nav-item" role="presentation" 
                      v-if="this.RecentSearchData.length > 0 ? true : false">
                        <button
                          @click="
                            (airbooking = false),
                              (mytrips = false),
                              (resentSearch = true)
                          "
                          :class="resentSearch ? 'true-btn' : 'false-btn'"
                          style="border-radius:0 20px 0 0"
                        >
                          <!-- <i class="fa-solid fa-plane me-1"></i> -->
                          <v-icon size="22" class="me-1">mdi-history</v-icon>
                          Recent Searches
                        </button>
                      </li>

                      <!-- <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="trips-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#trips-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="trips-tab-pane"
                          aria-selected="false"
                        >
                          <i class="fa-regular fa-file-lines"></i>my trips
                        </button>
                      </li> -->
                      <!-- <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="check-tab"
                                         data-bs-toggle="tab" data-bs-target="#check-tab-pane"
                                         
                                         
                                         type="button"
                                            role="tab" aria-controls="check-tab-pane" 
                                            aria-selected="false">
                                            <i class="fa-regular fa-circle-check"></i> check-in</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="flight-tab" data-bs-toggle="tab" data-bs-target="#flight-tab-pane" type="button"
                                            role="tab" aria-controls="flight-tab-pane" aria-selected="false">
                                            <i class="flaticon-clock"></i> Flight status</button>
                                    </li> -->
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div v-if="airbooking">
                        <div class="row">
                          <div class="col-lg-12" style="text-align: left">
                            <div class="tab-content-wrap">
                              <div class="content-top mb-2 row">
                                <div class="col-md-5 col-12">
                                  <v-radio-group v-model="radios" inline>
                                    <v-radio
                                      :label="this.$t('formsContents.options.roundTrip')"
                                      value="one"
                                      @click="act(1)"
                                    ></v-radio>
                                    <v-radio
                                      :label="this.$t('formsContents.options.oneWay')"
                                      value="two"
                                      @click="act(2)"
                                    ></v-radio>
                                    <v-radio
                                      :label="this.$t('formsContents.options.multiCity')"
                                      value="three"
                                      @click="act(3)"
                                    ></v-radio>
                                  </v-radio-group>
                                </div>
                                <div v-if="multicity" class="col-md-2 col-5">
                                  <div
                                    class="input-styles-2 select-option rounded-border mt-1"
                                  >
                                    <!-- <v-select
                                      v-model="classType"
                                      ref="mySelect"
                                      class="select-2"
                                      @click="selectFunction()"
                                      :items="classTypeitems"
                                      variant="underlined"
                                      item-title="text"
                                      style="color: black"
                                    ></v-select> -->
                                    <!-- {{ console.log(this.classTypeitems,'classTypeItemsClass') }} -->
                                    <v-select
                                      v-model="classType"
                                      :items="classTypeitems"
                                      ref="mySelect"
                                      class="select-2"
                                      variant="underlined"
                                      item-title="text"
                                      style="color: black"
                                    >
                                      <template
                                        v-slot:item="{ props, item, index }"
                                      >
                                        <v-list-item
                                          v-bind="props"
                                          :subtitle="item.department"
                                          @click="selectClasstype(item, index)"
                                        >
                                        </v-list-item>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>
                                <div v-if="multicity" class="col-md-3 col-7">
                                  <div
                                    class="input-styles-2 select-option rounded-border mt-1"
                                    style="height: 30px"
                                  >
                                    <v-select
                                      v-model="selectedCountry"
                                      :items="countries"
                                      ref="countrySelect"
                                      variant="underlined"
                                      style="position: relative; top: 0px"
                                      class="select-1"
                                    >
                                      <!-- <template #item="{ item }"> -->
                                      <template #item="{}">
                                        <div
                                          class="dropdown_section"
                                          style="cursor: pointer"
                                        >
                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            style="height: 37px"
                                            @click.stop
                                          >
                                            <div
                                              class="col-3"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >{{ $t("formsContents.options.adult") }}</span
                                              >
                                            </div>
                                            <div
                                              class="col-3 px-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-human-male-female
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%; height: auto"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="deg1()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="add1()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <div>{{ item.name }}</div> -->
                                          </div>

                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            @click.stop
                                          >
                                            <div
                                              class="col-4 d-flex flex-column pe-0"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >{{ $t("formsContents.options.children") }}</span
                                              >
                                              <span
                                                style="
                                                  font-size: 11px;
                                                  color: #4e4d4d;
                                                "
                                                >2-11(yrs)</span
                                              >
                                            </div>
                                            <div
                                              class="col-2 px-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-human-child
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="deg2()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls1 }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="add2()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <div>{{ item.name }}</div> -->
                                          </div>

                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            @click.stop
                                          >
                                            <div
                                              class="col-5 d-flex flex-column pe-0"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >{{ $t("formsContents.options.infant") }}</span
                                              >
                                              <span
                                                style="
                                                  font-size: 11px;
                                                  color: #4e4d4d;
                                                "
                                                >2(yrs)</span
                                              >
                                            </div>

                                            <div
                                              class="col-1 ps-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-baby-buggy
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="deg3()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls2 }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons text-center"
                                                  @click="add3()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            class="mt-4 pe-3 d-flex justify-end"
                                            @click="
                                              (travels = false),
                                                localdone(),
                                                closeDropdown()
                                            "
                                          >
                                            <v-btn
                                              rounded="3"
                                              color="#26225e"
                                              width="100px"
                                              height="30px"
                                              style="color: white"
                                            >
                                              {{ $t("formsContents.options.done") }}
                                            </v-btn>
                                          </div>
                                        </div>
                                      </template>

                                      <template #selection="{}">
                                        <div class="pax-fonts">
                                          <span class="adt-chd"
                                            >{{ aduls }} {{ $t("formsContents.options.adt") }}</span>
                                          <span
                                            v-if="aduls1 > 0"
                                            class="adt-chd"
                                            >, {{ aduls1 }} {{ $t("formsContents.options.chl") }}</span
                                          >
                                          <span
                                            v-if="aduls2 > 0"
                                            class="adt-chd"
                                            >, {{ aduls2 }} {{ $t("formsContents.options.inf") }}</span
                                          >

                                          <!-- <span class="adt-chd"
                                            >, {{ classType }}</span
                                          > -->
                                        </div>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>
                              </div>
                              <div v-if="rount || Oneway">
                                <form action="#" class="booking-form">
                                  <div
                                    class="py-2 ps-2 row flex-row justify-content-between mobileForm"
                                    style="
                                      background-color: rgb(227 225 223);
                                      margin: 0;height:53px;
                                    "
                                  >
                                    <div class="col-md-3 col-12 ps-2" @click="clickFocus()" style="height:45px;">
                                      <div class="form-grp">
                                        <div class="labelTop" style="top:0px;height:16px;">
                                          <p class="m-0 ps-2">{{ $t("formsContents.labels.fromlabel") }}</p>
                                        </div>
                                        <div
                                          class="d-flex input-styles align-center rounded-border mb-2"
                                        >
                                          <span class="p-float-label">
                                            <!-- <AutoComplete
                                              v-model="oneway.from"
                                              optionLabel="name"
                                              dropdown
                                              forceSelection
                                              :suggestions="filteredCountries"
                                              @complete="search"
                                              @change="onChangeFrom()"
                                              @click="fromClick(oneway)"
                                              ref="fromAutoComplete"
                                              class="mb-2"
                                              style="
                                                width: 100%;
                                                height: 100%;
                                                font-size: 16px;
                                                font-weight: 600;
                                                top: -15px;
                                              "
                                            >
                                              <template #option="slotProps">
                                                <div
                                                  class="flex align-items-center dropdown-box"
                                                  @click="focus1()"
                                                >
                                                  <div>
                                                    {{
                                                      slotProps.option
                                                        .originalName
                                                    }}
                                                  </div>
                                                </div>
                                              </template>
                                            </AutoComplete> -->
                                          
                                            <div class="vas-autocomplete">
                                              <div class="d-flex align-center">
                                                <div>
                                                  <input type="text"
                                                    v-model="oneway.from"
                                                    @keyup="typeCity(oneway.from, 1)"
                                                    class="inter-input"
                                                    @click="(oneway.from = ''),(formres = true),(formres1 = false);city1 = [];"
                                                    @change="onChangeFrom()"
                                                    ref="fromAutoComplete"
                                                  />
                                                </div>

                                                <div v-if="selectedFrom">
                                                  <v-icon @click="fromInputData()" size="16" color="grey">mdi-close</v-icon>
                                                </div>
                                              </div>
                                              <!-- {{
                                                console.log(
                                                  inputCity,
                                                  "inputCityinputCity"
                                                )
                                              }} -->
                                              <!-- <div
                                                @click="onewaySwap"
                                                class="pos-swap"
                                              >
                                                <v-icon
                                                  size="30"
                                                  color="white"
                                                  :class="{
                                                    rotateSwap: !swapData,
                                                    normalSwap: swapData,
                                                  }"
                                                  >mdi-swap-horizontal</v-icon
                                                >
                                              </div> -->
                                              <!-- {{ console.log(this.swapData, "this.formres") }} -->
                                              <div v-if="typedrop">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if="this.city.length > 0">
                                                  <div v-for="(data, index) in city" :key="index">
                                                    <div @click="datachange(data)"
                                                      :style="index == city.length - 1 ? '' : 'border-bottom:1px solid lightgray'"
                                                      class="d-flex va-back">
                                                      <div style="display: flex;align-items: center;">
                                                        <v-icon style="transform: rotate(45deg);"
                                                          color="gray"
                                                          size="20">
                                                          {{data.location === "airport" ? "mdi-airplane" : "mdi-radiobox-marked"}}</v-icon>
                                                      </div>

                                                      <div class="m-1 va-content">
                                                        {{ data.iata }},{{data.city}},{{ data.name }}
                                                      </div>
                                                    </div>
                                                    <!-- -------group--- -->

                                                    <div v-if="data.group_airports.length > 0">
                                                      <div v-for="group in data.group_airports" :key="group">
                                                        <div v-if="data.group_airport_city != group.iata">
                                                          <div class="d-flex va-back" @click="datachange(group)">
                                                            <div style="display: flex;align-items: center;"
                                                              class="ms-3">
                                                              <v-icon style="transform: rotate(45deg);"
                                                                color="gray"
                                                                size="20"
                                                                >mdi-airplane</v-icon>
                                                            </div>

                                                            <div class="m-1 va-content">
                                                              {{group.iata}},{{group.city}},{{ group.name}}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- <label
                                              for="ac"
                                              class="fromLabel"
                                              style="top: -27px"
                                              >Leaving From</label
                                            > -->
                                          </span>
                                          <div v-if="fromloader">
                                            <div class="spinner-border spinner-border-sm" 
                                            style="display:flex;align-items:center;color:rgb(38 34 94);" role="status"></div>
                                          </div>
                                        </div>
                                          <!-- {{ console.log(fromErrorMessage, maximumletter,'errorMessage') }} -->
                                        <div class="text-start">
                                          <span
                                            class="p-error wrap-text"
                                            v-if="fromErrorMessage"
                                            >{{ $t("formsContents.errorMsg.departReq") }}</span
                                          >
                                          <br
                                            v-if="
                                              fromErrorMessage && maximumletter
                                            "
                                          />
                                          <span
                                            class="p-error wrap-text"
                                            v-if="maximumletter"
                                            >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                                          >
                                        </div>

                                        <!-- <div v-if="formres">
                                          <div
                                            v-if="resultFrom.length"
                                            class="recent_style"
                                          >
                                            <v-card
                                              style="
                                                max-height: 300px;
                                                border-radius: 10px;
                                                padding: 5px;
                                              "
                                            >
                                              <h5
                                                style="
                                                  text-align: left;
                                                  margin: 12px;
                                                  font-weight: 500;
                                                  font-size: 20px;
                                                "
                                              >
                                                Recent Search From
                                              </h5>
                                              <div
                                                class="row resu-form mt-1 align-center"
                                                v-for="data of resultFrom"
                                                :key="data"
                                              >
                                                <div class="col-2">
                                                  <v-icon
                                                    size="22"
                                                    class="float-end"
                                                  >
                                                    mdi-airplane-takeoff
                                                  </v-icon>
                                                </div>

                                                <div
                                                  class="col-10 ps-0"
                                                  @click="getrecent(data)"
                                                >
                                                  <div>
                                                    {{
                                                      getrecentData(
                                                        data.from.originalName
                                                      )
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </v-card>
                                          </div>
                                        </div> -->
                                      </div>
                                    </div>
                                    <div class="col-md-3 col-12 ps-2" @click="clickFocusTo()" style="height:45px;">
                                      <div class="form-grp">
                                        <div class="labelTop" style="top:0px;height:16px;">
                                          <p
                                            class="m-0 ps-md-4 ms-md-2 ps-2 ms-0"
                                          >
                                            {{ $t("formsContents.labels.tolabel") }}
                                          </p>
                                        </div>
                                        <div
                                          class="d-flex input-styles align-center rounded-border roundLabel mb-2 ps-4 ms-2"
                                          @click="formres1 = true"
                                        >
                                          <span class="p-float-label">
                                            <div class="vas-autocomplete">
                                              <div class="d-flex align-center">
                                                <div>
                                                  <input
                                                    type="text"
                                                    v-model="oneway.to"
                                                    @keyup="
                                                      typeCity(oneway.to, 2)
                                                    "
                                                    class="inter-input"
                                                    @click="
                                                      (oneway.to = ''),
                                                        (formres1 = true),
                                                        (formres = false),
                                                        (city = [])
                                                    "
                                                    @change="onChangeTo()"
                                                    ref="toAutoComplete"
                                                  />
                                                </div>
                                                <div v-if="selectedTo">
                                                    <v-icon @click="toInputData()" size="16" color="grey">mdi-close</v-icon>
                                                  </div>
                                                </div>
                                              <div v-if="typedrop1">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if="this.city1.length > 0"
                                                >
                                                  <div
                                                    v-for="(
                                                      data, index
                                                    ) in city1"
                                                    :key="index"
                                                  >
                                                    <div
                                                      @click="
                                                        datachangeTo(data)
                                                      "
                                                      :style="
                                                        index ==
                                                        city1.length - 1
                                                          ? ''
                                                          : 'border-bottom:1px solid lightgray'
                                                      "
                                                      class="d-flex va-back"
                                                    >
                                                      <div
                                                        style="
                                                          display: flex;
                                                          align-items: center;
                                                        "
                                                      >
                                                        <v-icon
                                                          style="
                                                            transform: rotate(
                                                              45deg
                                                            );
                                                          "
                                                          color="gray"
                                                          size="20"
                                                        >
                                                          {{
                                                            data.location ===
                                                            "airport"
                                                              ? "mdi-airplane"
                                                              : "mdi-radiobox-marked"
                                                          }}
                                                        </v-icon>
                                                      </div>

                                                      <div
                                                        class="m-1 va-content"
                                                      >
                                                        {{ data.iata }},{{
                                                          data.city
                                                        }},{{ data.name }}
                                                      </div>
                                                    </div>

                                                    <div
                                                      class="ms-3"
                                                      v-if="
                                                        data.group_airports
                                                          .length > 0
                                                      "
                                                    >
                                                      <div
                                                        v-for="group in data.group_airports"
                                                        :key="group"
                                                      >
                                                        <div
                                                          v-if="
                                                            data.group_airport_city !=
                                                            group.iata
                                                          "
                                                        >
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="
                                                              datachangeTo(
                                                                group
                                                              )
                                                            "
                                                          >
                                                            <div
                                                              style="
                                                                display: flex;
                                                                align-items: center;
                                                              "
                                                            >
                                                              <v-icon
                                                                style="
                                                                  transform: rotate(
                                                                    45deg
                                                                  );
                                                                "
                                                                color="gray"
                                                                size="20"
                                                                >mdi-airplane</v-icon
                                                              >
                                                            </div>

                                                            <div
                                                              class="m-1 va-content"
                                                            >
                                                              {{
                                                                group.iata
                                                              }},{{
                                                                group.city
                                                              }},{{
                                                                group.name
                                                              }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- <label for="ac">Going To</label> -->
                                          </span>
                                          <div v-if="toloader">
                                            <div class="spinner-border spinner-border-sm" 
                                            style="display:flex;align-items:center;color:rgb(38 34 94);" role="status"></div>
                                          </div>
                                        </div>
                                        <!-- {{ console.log(toErrorMessage,maximumletter1,'errorMessageTo') }} -->
                                        <div class="text-start ps-4">
                                          <span
                                            class="p-error wrap-text"
                                            v-if="toErrorMessage"
                                            >{{ $t("formsContents.errorMsg.arrivalReq") }}</span
                                          >
                                          <br
                                            v-if="
                                              toErrorMessage && maximumletter1
                                            "
                                          />
                                          <span
                                            class="p-error wrap-text"
                                            v-if="maximumletter1"
                                            >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                                          >
                                        </div>
                                        <button class="exchange-icon" @click.prevent="swapFromTo()">
                                          <i
                                            class="fa-solid fa-arrow-right-arrow-left"
                                          ></i>
                                        </button>

                                        <!-- <div v-if="formres1">
                                          <div
                                            v-if="resultTo.length"
                                            class="recent_style"
                                          >
                                            <v-card
                                              style="
                                                max-height: 300px;
                                                border-radius: 10px;
                                                padding: 5px;
                                              "
                                            >
                                              <h5
                                                style="
                                                  text-align: left;
                                                  margin: 12px;
                                                  font-weight: 500;
                                                  font-size: 20px;
                                                "
                                              >
                                                Recent Search To
                                              </h5>
                                              <div
                                                class="row resu-form mt-1"
                                                v-for="data of resultTo"
                                                :key="data"
                                              >
                                                <div class="col-2">
                                                  <v-icon
                                                    size="22"
                                                    class="float-end"
                                                  >
                                                    mdi-airplane-landing
                                                  </v-icon>
                                                </div>

                                                <div
                                                  class="col-10 ps-0"
                                                  @click="getrecent1(data)"
                                                >
                                                  <div>
                                                    {{
                                                      getrecentData(
                                                        data.to.originalName
                                                      )
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </v-card>
                                          </div>
                                        </div> -->
                                      </div>
                                    </div>
                                    <!-- <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortBy">Trip</label>
                                                                    <select id="shortBy" name="select" class="form-select mb-2" aria-label="Default select example">
                                                                        <option value="">Economy</option>
                                                                        <option>Business</option>
                                                                        <option>First Class</option>
                                                                    </select>
                                                                </div>
                                                            </li> -->
                                    <div class="col-md-3 col-12 p-0">
                                      <div class="form-grp date">
                                        <ul class="ps-0">
                                          <!-- <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li> -->
                                          <div>
                                            <div class="labelTop" style="top:-3px;">
                                              <p class="m-0 ps-0">
                                                {{ $t("formsContents.labels.departDate") }}
                                              </p>
                                            </div>

                                            <div
                                              class="d-flex input-styles align-center rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                            <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                        </span> -->

                                              <span class="p-float-label">
                                                <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                                                            Departure
                                                                            </span> -->

                                                <VueDatePicker
                                                  ref="dpRef1"
                                                  v-model="oneway.dedate"
                                                  :min-date="new Date()"
                                                  :six-weeks="sixWeekMode"
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :format="dateFormat"
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :multi-calendars="
                                                    multiCalender
                                                  "
                                                  @update:modelValue="
                                                    fromDateChanged
                                                  "
                                                  :on-click-outside="
                                                    ClickOutDepat
                                                  "
                                                  class="px-0 roundFromTime"
                                                />

                                                <!-- <div class="col-12"> -->
                                                <!-- <span class="p-error" v-if="fromDateError">Dep. Date is required</span> -->
                                                <!-- </div> -->
                                              </span>
                                            </div>
                                            <div
                                              class="text-start"
                                              v-if="fromDateError"
                                            >
                                              <span class="p-error wrap-text" style="word-wrap: break-word;white-space: normal;max-width:48%;"
                                                >{{ $t("formsContents.errorMsg.depdateReq") }}</span
                                              >
                                            </div>
                                          </div>

                                          <div :class="{ disable: Oneway }">
                                            <div class="labelTop" style="top:-3px;">
                                              <p class="m-0 ps-0">
                                                {{ $t("formsContents.labels.returnDate") }}
                                              </p>
                                            </div>
                                            <div
                                              class="d-flex input-styles align-center rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                                <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                            </span> -->
                                              <span class="p-float-label">
                                                <!-- <span :class="clicked2 ? 'label-top' : 'datepicker-label'">
                                                                                Return
                                                                                </span> -->
                                                <VueDatePicker
                                                  ref="dpRef2"
                                                  v-model="oneway.redate"
                                                  :format="dateFormat"
                                                  no-today
                                                  :six-weeks="sixWeekMode"
                                                  :start-date="oneway.dedate"
                                                  :multi-calendars="
                                                    multiCalender
                                                  " v-if="rount"
                                                  :disabled="red1 === 2"
                                                  :min-date="
                                                    oneway.dedate
                                                      ? oneway.dedate
                                                      : new Date()
                                                  "
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :on-click-outside="
                                                    ClickOutReturn
                                                  "
                                                  @update:modelValue="
                                                    toDateChanged
                                                  "
                                                  class="roundToTime"
                                                />
                                                <VueDatePicker
                                                  v-model="date"
                                                  placeholder=""
                                                  v-if="!rount"
                                                />

                                                <!-- <span class="p-error" v-if="toDateError">Arr. Date is required</span> -->
                                              </span>
                                            </div>
                                            <div class="text-start">
                                              <span
                                                class="p-error wrap-text"
                                                v-if="toDateError"
                                                >{{ $t("formsContents.errorMsg.retdateReq") }}</span
                                              >
                                            </div>
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                    <div class="col-md-3 col-12 p-0">
                                      <div class="form-grp economy">
                                        <div @click="travels = true">
                                          <label
                                            for="text"
                                            class=""
                                            style="line-height: 1.2;"
                                            >{{ $t("formsContents.options.passenger") }}/ {{ $t("formsContents.options.class") }}</label
                                          >
                                          <v-select
                                            v-model="selectedCountry"
                                            :items="countries"
                                            ref="countrySelect"
                                            class="select-1"
                                          >
                                            <!-- <template #item="{ item }"> -->
                                            <template #item="{}">
                                              <div
                                                class="dropdown_section"
                                                style="cursor: pointer"
                                                v-if="travels"
                                              >
                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  style="height: 37px"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-3"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >{{ $t("formsContents.options.adult") }}</span
                                                    >
                                                  </div>
                                                  <div
                                                    class="col-3 px-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-human-male-female
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="
                                                      width: 50%;
                                                      height: auto;
                                                    "
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg1()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add1()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <!-- <div>{{ item.name }}</div> -->
                                                </div>

                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-4 d-flex flex-column pe-0"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >{{ $t("formsContents.options.children") }}</span
                                                    >
                                                    <span
                                                      style="
                                                        font-size: 11px;
                                                        color: #4e4d4d;
                                                      "
                                                      >2-11(yrs)</span
                                                    >
                                                  </div>
                                                  <div
                                                    class="col-2 px-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-human-child
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="width: 50%"
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg2()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls1 }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add2()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <!-- <div>{{ item.name }}</div> -->
                                                </div>

                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-5 d-flex flex-column pe-0"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >{{ $t("formsContents.options.infant") }}</span
                                                    >
                                                    <span
                                                      style="
                                                        font-size: 11px;
                                                        color: #4e4d4d;
                                                      "
                                                      >2(yrs)</span
                                                    >
                                                  </div>

                                                  <div
                                                    class="col-1 ps-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-baby-buggy
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="width: 50%"
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg3()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls2 }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add3()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div class="col-4">
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: grey;
                                                      "
                                                      >{{ $t("formsContents.options.cabin") }}</span
                                                    >
                                                  </div>

                                                  <div
                                                    class="col-2"
                                                    align="center"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-seat-passenger
                                                    </v-icon>
                                                  </div>
                                                  <div class="col-6">
                                                    <div
                                                      class="input-styles-1 select-option select-2"
                                                    >
                                                      <v-select
                                                        v-model="classType"
                                                        ref="mySelect"
                                                        @click="selectFunction()"
                                                        :items="classTypeitems"
                                                        variant="underlined"
                                                        item-title="text"
                                                        style="color: gray"
                                                      ></v-select>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  align="end"
                                                  class="mt-4 pe-3 d-flex justify-end"
                                                  @click="localdone(), closeDropdown()">
                                                  <v-btn
                                                    rounded="3"
                                                    color="#26225e"
                                                    width="100px"
                                                    height="30px"
                                                    style="color: white"
                                                    @click="travels = false"
                                                  >
                                                    {{ $t("formsContents.options.done") }}
                                                  </v-btn>
                                                </div>
                                              </div>
                                            </template>

                                            <template #selection="{}">
                                              <div class="pax-fonts">
                                                <span class="adt-chd"
                                                  >{{ aduls }} {{ $t("formsContents.options.adt") }}</span
                                                >
                                                <span
                                                  v-if="aduls1 > 0"
                                                  class="adt-chd"
                                                  >, {{ aduls1 }} {{ $t("formsContents.options.chl") }}</span
                                                >
                                                <span
                                                  v-if="aduls2 > 0"
                                                  class="adt-chd"
                                                  >, {{ aduls2 }} {{ $t("formsContents.options.inf") }}</span
                                                >

                                                <span class="adt-chd"
                                                  >, {{ classType }}</span
                                                >
                                              </div>
                                            </template>
                                          </v-select>
                                        </div>
                                      </div>
                                      <!-- <div class="d-flex input-styles">

                                                               
                                                                </div> -->
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div class="my-4 mt-md-0 mt-5" v-if="multicity">
                                <v-form style="border-radius: 8px">
                                  <div
                                    class="row multicity m-0"
                                    v-for="(data, index) of multiData.itinery"
                                    :key="index">
                                    <ul
                                      class="multiData py-2 pb-3 ps-2 d-flex flex-md-row flex-column align-center justify-content-between"
                                      style="
                                        background-color: rgb(227 225 223);
                                        margin: 0;
                                        border-radius: 5px;height:72px;
                                      "
                                    >
                                      <li class="col-lg-3 col-12" @click="clickMultiFrom(index)">
                                        <div class="form-grp">
                                          <div class="labelTop">
                                            <p class="m-0 ps-2">
                                              {{ $t("formsContents.labels.flight") }} {{ index + 1 }} {{ $t("formsContents.labels.fromlabel") }}
                                            </p>
                                          </div>
                                          <div
                                            class="d-flex input-styles align-center rounded-border"
                                          >
                                            <span class="p-float-label">
                                              <!-- <AutoComplete
                                                v-model="data.from"
                                                optionLabel="name"
                                                dropdown
                                                forceSelection
                                                :suggestions="filteredCountries"
                                                @complete="search"
                                                @change="
                                                  onChangeMultiFrom(index)
                                                "
                                                @click="
                                                  multiFromClick(index),
                                                    (focusedElement = 'from')
                                                "
                                                ref="fromAutoComplete"
                                                class="mb-2"
                                                style="
                                                  width: 100%;
                                                  height: 100%;
                                                  font-size: 16px;
                                                  font-weight: 600;
                                                  top: -10px;
                                                "
                                              >
                                                <template #option="slotProps">
                                                  <div
                                                    class="flex align-items-center dropdown-box"
                                                    @click="multifocus1(index)"
                                                  >
                                                    <div>
                                                      {{
                                                        slotProps.option
                                                          .originalName
                                                      }}
                                                    </div>
                                                  </div>
                                                </template>
                                              </AutoComplete>
                                              <label
                                                for="ac"
                                                class="fromLabel"
                                                style="top: -38px; left: 0px"
                                                >Flight
                                                {{ index + 1 }} From</label
                                              > -->

                                              <div class="vas-autocomplete">
                                                <div class="d-flex align-center">
                                                  <div>
                                                    <input
                                                      type="text"
                                                      style="cursor: pointer"
                                                      v-model="data.from"
                                                      @click="
                                                        multiData.itinery[
                                                          index
                                                        ].from = ''
                                                      "
                                                      @keyup="
                                                        typeMultiCity(
                                                          data.from,
                                                          index,
                                                          1
                                                        )
                                                      " ref="multiFromAutocomplete"
                                                      class="inter-input"
                                                    />
                                                  </div>
                                                  <div v-if="data.selectedFrom">
                                                    <v-icon @click="multiFrom(index)" size="16" color="grey">mdi-close</v-icon>
                                                  </div>
                                                </div>
                                                <!-- {{console.log(data,'data.autocomfrom')}} -->
                                                <div
                                                  v-if="
                                                    multiData.itinery[index]
                                                      .autocomfrom
                                                      ? true
                                                      : false
                                                  "
                                                >
                                                  <div
                                                    class="autocompete-menual-dropdown"
                                                    v-if="
                                                      this.multidrop.length > 0
                                                        ? true
                                                        : false
                                                    "
                                                  >
                                                    <div
                                                      v-for="(
                                                        data1, index1
                                                      ) in multidrop"
                                                      :key="index1"
                                                    >
                                                      {{
                                                        console.log(
                                                          data1,
                                                          "data1data1"
                                                        )
                                                      }}
                                                      <div
                                                        @click="
                                                          datachangeMuti(
                                                            data1,
                                                            index
                                                          )
                                                        "
                                                        :style="
                                                          index1 ==
                                                          multidrop.length - 1
                                                            ? ''
                                                            : 'border-bottom:1px solid lightgray'
                                                        "
                                                        class="d-flex p-2 va-back"
                                                      >
                                                        <div
                                                          style="
                                                            display: flex;
                                                            align-items: center;
                                                          "
                                                        >
                                                          <v-icon
                                                            style="
                                                              transform: rotate(
                                                                45deg
                                                              );
                                                            "
                                                            color="gray"
                                                            size="20"
                                                            >mdi-airplane</v-icon
                                                          >
                                                        </div>

                                                        <div
                                                          class="ms-1 va-content"
                                                        >
                                                          {{ data1.iata }},
                                                          {{ data1.city }},
                                                          {{ data1.name }}
                                                        </div>
                                                      </div>

                                                      <div
                                                        v-if="
                                                          data1.group_airports
                                                            .length > 0
                                                        "
                                                      >
                                                        <div
                                                          v-for="(
                                                            group, index1
                                                          ) in data1.group_airports"
                                                          :key="index1"
                                                        >
                                                          <div
                                                            v-if="
                                                              data1.group_airport_city !=
                                                              group.iata
                                                            "
                                                          >
                                                            <div
                                                              class="d-flex va-back"
                                                              @click="
                                                                datachangeMuti(
                                                                  group,
                                                                  index1
                                                                )
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: flex;
                                                                  align-items: center;
                                                                "
                                                                class="ms-3"
                                                              >
                                                                <v-icon
                                                                  style="
                                                                    transform: rotate(
                                                                      45deg
                                                                    );
                                                                  "
                                                                  color="gray"
                                                                  size="20"
                                                                  >mdi-airplane</v-icon
                                                                >
                                                              </div>

                                                              <div
                                                                class="m-1 va-content"
                                                              >
                                                                {{
                                                                  group.iata
                                                                }},
                                                                {{
                                                                  group.city
                                                                }},
                                                                {{ group.name }}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                            <div v-if="data.autocomfrom">
                                              <div class="spinner-border spinner-border-sm" 
                                              style="display:flex;align-items:center;color:rgb(38 34 94);" role="status"></div>
                                            </div>
                                          </div>

                                          <div class="text-start ps-2">
                                            <span class="p-error" v-if="data.multiFromError">{{ $t("formsContents.errorMsg.departReq") }}</span>
                                              <br v-if="data.multiFromError && data.maximumletter" />
                                            <span
                                              class="p-error"
                                              v-if="data.maximumletter"
                                              >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                                            >
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-3 col-12" @click="clickMultiFrom(index)">
                                        <div class="form-grp">
                                          <div class="labelTop">
                                            <p class="m-0 ps-2">
                                              {{ $t("formsContents.labels.flight") }} {{ index + 1 }} {{ $t("formsContents.labels.tolabel") }}
                                            </p>
                                          </div>
                                          <div
                                            class="d-flex input-styles align-center rounded-border"
                                            @click="formres1 = true"
                                          >
                                            <span
                                              class="p-float-label multiDataTo"
                                            >
                                              <div class="vas-autocomplete">
                                                <div class="d-flex align-center">
                                                  <div>
                                                    <input
                                                      style="cursor: pointer"
                                                      type="text"
                                                      v-model="data.to"
                                                      @click="
                                                        multiData.itinery[
                                                          index
                                                        ].to = ''
                                                      "
                                                      @keyup="
                                                        typeMultiCity(
                                                          data.to,
                                                          index,
                                                          2
                                                        )
                                                      " ref="multiToAutocomplete"
                                                      class="inter-input"
                                                    />
                                                  </div>
                                                  <div v-if="data.selectedTo">
                                                    <v-icon @click="multiTo(index)" size="16" color="grey">mdi-close</v-icon>
                                                  </div>
                                                </div>

                                                <div v-if="data.autocomTo">
                                                  <div
                                                    class="autocompete-menual-dropdown"
                                                    v-if="
                                                      multidrop1.length > 0
                                                        ? true
                                                        : false
                                                    "
                                                  >
                                                    <div
                                                      v-for="(
                                                        data, index1
                                                      ) in multidrop1"
                                                      :key="index1"
                                                    >
                                                      <div
                                                        @click="
                                                          datachangeMutiTo(
                                                            data,
                                                            index
                                                          )
                                                        "
                                                        :style="
                                                          index1 ==
                                                          multidrop1.length - 1
                                                            ? ''
                                                            : 'border-bottom:1px solid lightgray'
                                                        "
                                                        class="d-flex p-2 va-back"
                                                      >
                                                        <div
                                                          style="
                                                            display: flex;
                                                            align-items: center;
                                                          "
                                                        >
                                                          <v-icon
                                                            style="
                                                              transform: rotate(
                                                                45deg
                                                              );
                                                            "
                                                            color="gray"
                                                            size="20"
                                                          >
                                                            {{
                                                              data.location ===
                                                              "airport"
                                                                ? "mdi-airplane"
                                                                : "mdi-radiobox-marked"
                                                            }}
                                                          </v-icon>
                                                        </div>

                                                        <div
                                                          class="ms-1 va-content"
                                                        >
                                                          {{ data.iata }},
                                                          {{ data.city }},
                                                          {{ data.name }}
                                                        </div>
                                                      </div>

                                                      <div
                                                        v-if="
                                                          data.group_airports
                                                            .length > 0
                                                        "
                                                      >
                                                        <div
                                                          v-for="(
                                                            group, index1
                                                          ) in data.group_airports"
                                                          :key="index1"
                                                        >
                                                          <div
                                                            v-if="
                                                              data.group_airport_city !=
                                                              group.iata
                                                            "
                                                          >
                                                            <div
                                                              class="d-flex va-back"
                                                              @click="
                                                                datachangeMutiTo(
                                                                  group,
                                                                  index1
                                                                )
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: flex;
                                                                  align-items: center;
                                                                "
                                                                class="ms-3"
                                                              >
                                                                <v-icon
                                                                  style="
                                                                    transform: rotate(
                                                                      45deg
                                                                    );
                                                                  "
                                                                  color="gray"
                                                                  size="20"
                                                                  >mdi-airplane</v-icon
                                                                >
                                                              </div>

                                                              <div
                                                                class="m-1 va-content"
                                                              >
                                                                {{
                                                                  group.iata
                                                                }},
                                                                {{
                                                                  group.city
                                                                }},
                                                                {{ group.name }}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                            <div v-if="data.autocomTo">
                                              <div class="spinner-border spinner-border-sm" 
                                              style="display:flex;align-items:center;color:rgb(38 34 94);" role="status"></div>
                                            </div>
                                          </div>
                                          <div class="text-start ps-2">
                                            <span class="p-error" v-if="data.multiToError">{{ $t("formsContents.errorMsg.arrivalReq") }}</span>
                                              <br v-if="data.multiToError && data.maximumletter1" />
                                            <span
                                              class="p-error"
                                              v-if="data.maximumletter1"
                                              >{{ $t("formsContents.errorMsg.enterMinimum") }}</span
                                            >
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-3 col-12">
                                        <div class="form-grp date">
                                          <div>
                                            <div class="labelTop">
                                              <p class="m-0 ps-2">
                                                {{ $t("formsContents.labels.flight") }} {{ index + 1 }} {{ $t("formsContents.labels.datelabel") }}
                                              </p>
                                            </div>

                                            <div
                                              class="d-flex input-styles rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                            <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                        </span> -->

                                              <span
                                                class="p-float-label multiDate"
                                              >
                                                <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                                                            Departure
                                                                            </span> -->

                                                <VueDatePicker
                                                  ref="dpRef4"
                                                  v-model="data.departDate"
                                                  :six-weeks="sixWeekMode"
                                                  :min-date="
                                                    getdate1(
                                                      multiData.itinery,
                                                      index
                                                    )
                                                  "
                                                  :start-date="
                                                    getdate1(
                                                      multiData.itinery,
                                                      index
                                                    )
                                                  "
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :format="dateFormat"
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :multi-calendars="
                                                    multiCalender
                                                  "
                                                  @focus="
                                                    (data.focusMultiEvent3 = true),
                                                      (data.focusMultiEvent1 = false),
                                                      (data.focusMultiEvent2 = false)
                                                  "
                                                  @update:modelValue="
                                                    (newValue) =>
                                                      multiDateChanged(
                                                        newValue,
                                                        index
                                                      )
                                                  "
                                                  :month-change-on-scroll="
                                                    false
                                                  "
                                                  :on-click-outside="
                                                    ClickOutDepatMulti
                                                  "
                                                  class="px-0"
                                                />

                                                <!-- <div class="col-12"> -->
                                                <!-- <span class="p-error" v-if="fromDateError">Dep. Date is required</span> -->
                                                <!-- </div> -->
                                              </span>
                                            </div>
                                            <div class="text-start ps-2">
                                              <span
                                                class="p-error"
                                                v-if="data.multiDateError"
                                                >{{ $t("formsContents.errorMsg.depdateReq") }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-2 col-12">
                                        <div
                                          class="content-bottom"
                                          style="margin: 0 !important"
                                        >
                                          <v-btn
                                            v-if="index == 0 ? true : false"
                                            block
                                            class="d-md-flex d-none search-btn btn m-0"
                                            :disabled="disButton"
                                            @click="sendedMulticity()"
                                            ><span
                                              class="f-size-20 me-1 text-capitalize"
                                              >{{ $t("formsContents.options.showFlight") }}</span
                                            >
                                          </v-btn>
                                          <div
                                            class="d-flex"
                                            v-if="index !== 0 ? true : false"
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                                margin-right: 10px;
                                              "
                                            >
                                              <span
                                                style="
                                                  margin: auto;
                                                  cursor: pointer;
                                                "
                                                v-if="index > 1 ? true : false"
                                                @click="deleteItin(index)"
                                              >
                                                <v-icon color="red">
                                                  mdi-close-circle-outline
                                                </v-icon>
                                              </span>
                                            </div>

                                            <div
                                              v-if="
                                                multiData.itinery.length == 4
                                                  ? false
                                                  : true
                                              "
                                            >
                                              <v-btn
                                                v-if="
                                                  multiData.itinery.length -
                                                    1 ==
                                                    3 ||
                                                  index ==
                                                    multiData.itinery.length - 1
                                                    ? true
                                                    : false
                                                "
                                                class="d-flex align-center formbottom_addBtn"
                                                @click="addTrip(index)"
                                                prepend-icon="mdi-plus-circle"
                                                >{{ $t("formsContents.options.addMore") }}</v-btn
                                              >
                                            </div>

                                            <div
                                              v-if="index == 3 ? true : false"
                                            >
                                              <v-btn
                                                class="d-flex align-center formbottom_ClearBtn"
                                                @click="clearall1()"
                                                prepend-icon="mdi-close-box-multiple"
                                                >{{ $t("formsContents.options.clearAll") }}</v-btn
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </v-form>
                              </div>

                              <div
                                class="content-bottom"
                                v-if="rount || Oneway"
                              >
                                <!-- <a href="booking-details.html" class="promo-code">+ Add Promo code</a> -->
                                <!-- <a href="/search" class="btn">Show Flights <i class="flaticon-flight-1"></i></a> -->
                                <div class="text-center">
                                  <v-btn
                                    block
                                    class="search-btn btn"
                                    v-if="rount"
                                    :disabled="disButton"
                                    rounded="2"
                                    @click="sended()"
                                    ><span
                                      class="f-size-20 me-1 text-capitalize"
                                      >{{ $t("formsContents.options.showFlight") }}</span
                                    >
                                  </v-btn>
                                  <v-btn
                                    block
                                    class="search-btn btn"
                                    v-if="Oneway"
                                    :disabled="disButton"
                                    rounded="2"
                                    @click="sendedone()"
                                    ><span
                                      class="f-size-20 me-1 text-capitalize"
                                      >{{ $t("formsContents.options.showFlight") }}</span
                                    >
                                  </v-btn>
                                </div>
                              </div>
                              <div
                                class="content-bottom d-md-none d-flex"
                                v-if="multicity"
                              >
                                <v-btn
                                  block
                                  class="d-flex search-btn btn m-0"
                                  :disabled="disButton"
                                  @click="sendedMulticity()"
                                  ><span class="f-size-20 me-1 text-capitalize"
                                    >{{ $t("formsContents.options.showFlight") }}</span
                                  >
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="mytrips">
                        <div class="tab-content-wrap">
                          <v-form
                            v-model="valid"
                            ref="form"
                            @submit.prevent="bookinglist"
                          >
                            <div class="row" style="padding: 20px 0px">
                              <div class="col-md-5 mb-lg-0 mb-2">
                                <v-text-field
                                  :label="this.$t('formsContents.labels.bookingRef')"
                                  class="view-book"
                                  v-model="bookid"
                                  :rules="[(v) => !!v || 'Booking Reference']"
                                  variant="outlined"
                                ></v-text-field>
                              </div>

                              <div class="col-md-5 mb-lg-0 mb-2">
                                <v-text-field
                                  :label="this.$t('formsContents.labels.lastName')"
                                  class="view-book"
                                  :rules="[
                                    (v) => !!v || 'Last Name is required',
                                  ]"
                                  v-model="lastname"
                                  variant="outlined"
                                  @input="capitalizeLastName"
                                ></v-text-field>
                              </div>

                              <div class="col-md-2">
                                <v-btn
                                  type="submit"
                                  width="100%"
                                  style="height: 45px !important"
                                  color="#26225e"
                                  >{{ $t("homePageContent.contentSection.submit") }}</v-btn
                                >
                              </div>
                            </div>
                          </v-form>
                        </div>
                      </div>
                      <!-- 
 class="tab-pane fade show"
                        id="trips-tab-pane"
                        role="tabpanel"
                        aria-labelledby="trips-tab"
                        tabindex="0" -->

                      <div v-if="resentSearch">
                        <div class="tab-content-wrap recentform" style="min-height: 137px">
                          <div
                            v-if="
                              this.RecentSearchData.length > 0 ? true : false
                            "
                          >
                            <div class="row">
                              <div
                                class="col-md-4 mt-2"
                                v-for="data of this.RecentSearchData"
                                :key="data"
                              >
                              <div v-if="!data.itinery">
                                <v-card
                                  @click="cardassigndata(data)"
                                  class="pa-2"
                                >
                                  <div class="row">
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ data.from }}
                                      </div>
                                      <div class="re-fon">
                                        {{ getdated(data.fromDate) }}
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <div v-if="data.city == 1">
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                      </div>
                                      <div v-if="data.city == 2">
                                        <v-icon>mdi-arrow-right</v-icon>
                                      </div>
                                      <div v-if="data.city == 3">
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ data.to }}
                                      </div>
                                      <div class="re-fon" v-if="data.toDate">
                                        {{ getdated(data.toDate) }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7">
                                      <div class="re-fon">
                                        {{ $t("formsContents.options.passengers") }}:
                                        <span v-if="data.adult > 0"
                                          >{{ data.adult }}{{ $t("formsContents.options.adt") }}</span
                                        >
                                        <span class="ms-1" v-if="data.child > 0"
                                          >{{ data.child }}{{ $t("formsContents.options.chl") }}</span
                                        >
                                        <span class="ms-1" v-if="data.inf > 0"
                                          >{{ data.inf }}{{ $t("formsContents.options.inf") }}</span
                                        >
                                      </div>
                                    </div>
                                    <div class="col-5 re-fon" v-if="data.class">
                                      {{ $t("formsContents.options.class") }}: {{ data.class }}
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                                <div v-if="data.itinery">
                                  <div>
                                <v-card 
                                  @click="cardassigndata(data)"
                                  class="pa-2"
                                >
                                  <div class="row" v-for="(item,index) of data.itinery" :key="index">
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ item.from }}
                                      </div>
                                      <div class="re-fon">
                                        {{ item.depdata }}
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <div>
                                        <v-icon>mdi-arrow-right</v-icon>
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ item.to }}
                                      </div>
                                      <!-- <div class="re-fon" v-if="data.toDate">
                                        {{ getdated(data.toDate) }}
                                      </div> -->
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7">
                                      <div class="re-fon">
                                        {{ $t("formsContents.options.passengers") }}:
                                        <span v-if="data.adult > 0"
                                          >{{ data.adult }}{{ $t("formsContents.options.adt") }}</span
                                        >
                                        <span class="ms-1" v-if="data.child > 0"
                                          >{{ data.child }}{{ $t("formsContents.options.chl") }}</span
                                        >
                                        <span class="ms-1" v-if="data.inf > 0"
                                          >{{ data.inf }}{{ $t("formsContents.options.inf") }}</span
                                        >
                                      </div>
                                    </div>
                                    <div class="col-5 re-fon" v-if="data.class">
                                      {{ $t("formsContents.options.class") }}: {{ data.class }}
                                    </div>
                                  </div>
                                </v-card>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="single-slider slider-bg" data-background="assets/img/slider/slider_bg02.jpg">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-8 col-lg-10">
                                    <div class="slider-content">
                                        <h2 class="title" data-animation="fadeInUp" data-delay=".2s">A Lifetime of Discounts? It's Genius.</h2>
                                        <p data-animation="fadeInUp" data-delay=".4s">Get rewarded for your travels – unlock instant savings of 10% or more with a free Geairinfo.com account</p>
                                        <a href="contact.html" class="btn" data-animation="fadeInUp" data-delay=".6s">Sign in / Register</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-slider slider-bg" data-background="assets/img/slider/slider_bg03.jpg">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-8 col-lg-10">
                                    <div class="slider-content">
                                        <h2 class="title" data-animation="fadeInUp" data-delay=".2s">A Lifetime of Discounts? It's Genius.</h2>
                                        <p data-animation="fadeInUp" data-delay=".4s">Get rewarded for your travels – unlock instant savings of 10% or more with a free Geairinfo.com account</p>
                                        <a href="contact.html" class="btn" data-animation="fadeInUp" data-delay=".6s">Sign in / Register</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
      </div>
      <!-- </v-carousel-item>
    </v-carousel> -->
    </section>
    <!-- slider-area-end -->


    <!-- features-area -->
    <section class="features-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-help"></i> -->
                <img src="../assets/img/icon/help.png" />
              </div>
              <div class="features-content">
                <h6 class="title">We are now available</h6>
                <p>We're here to assist you 24/7. Reach out anytime!</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-plane"></i> -->
                <img src="../assets/img/icon/plane(1).png" />
              </div>
              <div class="features-content">
                <h6 class="title">International Flight</h6>
                <p> We're here to make your booking quick and easy.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-money-back-guarantee"></i> -->
                <img src="../assets/img/icon/refund.png" />
              </div>
              <div class="features-content">
                <h6 class="title">Check Refund</h6>
                <p>Easily track your refund status with us.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- features-area-end -->

    <!-- fly-next-area -->
    <section class="fly-next-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span class="sub-title">{{ $t("homePageContent.hightlightContent.heading1") }}</span>
              <h2 class="title">{{ $t("homePageContent.hightlightContent.subHead1") }}</h2>
            </div>
          </div>
        </div>
        <div class="row fly-next-active justify-content-center">
           <!-- <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
            v-for="(data,index) in topDestinations" :key="index"
          >{{ console.log(topDestinations,'topDestinationstopDestinations') }}
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a>
                  <img :src="require(`@/assets/img/images/${data.img}`)" alt="Flight Image">
                </a>
              </div>
              <div class="fly-next-content">
                <span>{{ data.fromDate }} - {{ data.toDate }}</span>
                <h4 class="title">{{data.from}}</h4>
                <a class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">{{data.to}}</h4>
                <a class="air-logo"
                  ><img :src="require(`@/assets/img/icon/${data.icon}`)" alt=""
                /></a>
                <div class="content-bottom">
                  <p>{{data.cabinType}}</p>
                  <h4 class="price">${{data.price}}</h4>
                </div>
              </div>
            </div>
          </div> -->
           <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a 
                  ><img src="../assets/img/images/fly_img01.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2024 - 16 Jun 2024</span>
                <h4 class="title">Dubai (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img02.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>12 Mar 2024 - 26 Jun 2024</span>
                <h4 class="title">Switzerland (SWL)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">Paris (CDG)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$800</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img03.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>02 Jan 2024 - 06 Jun 2024</span>
                <h4 class="title">Denmark (DEK)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">Sydney (SYD)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon03.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy</p>
                  <h4 class="price">$ 350</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img04.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>08 Oct 2024 - 20 Nov 2024</span>
                <h4 class="title">New York (USA)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">Jakarta (DXB)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$ 220</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img05.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>01 Apr 2024 - 13 Oct 2024</span>
                <h4 class="title">Toronto (YTO)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon03.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img06.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>12 May 2024 - 20 Jun 2024</span>
                <h4 class="title">Dubai (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">Kingston (KIN)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$175</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img07.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>29 Sep 2024 - 16 Dec 2024</span>
                <h4 class="title">Ottawa (YOW)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">London (LHR)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a
                  ><img src="../assets/img/images/fly_img08.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>05 Dec 2024 - 23 Jan 2024</span>
                <h4 class="title">Montreal (YUL)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$350</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- fly-next-area-end -->

    <!-- flight-offer-area -->
    <section class="flight-offer-area">
      <div class="container">
        <div class="row align-items-center mb-35">
          <div class="col-md-8 mb-3">
            <div class="section-title">
              <span class="sub-title">{{ $t("homePageContent.hightlightContent.heading2") }}</span>
              <h2 class="title">{{ $t("homePageContent.hightlightContent.subHead2") }}</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="best-price text-end">
              <a
                >Best Price Guarantee <i class="flaticon-check"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-10">
            <div class="flight-offer-item">
              <div class="flight-offer-thumb">
                <img src="../assets/img/images/offer_img01.jpg" alt="" />
              </div>
              <div class="flight-offer-content">
                <h2 class="title">Dhaka to Dubai</h2>
                <span>09 Jan 2024 - 16 Jan 2024</span>
                <p>Economy</p>
                <h4 class="price">$ 980</h4>
              </div>
              <div class="overlay-content">
                <h2 class="title">Dhaka to Dubai</h2>
                <span>09 Jan 2024 - 16 Jan 2024</span>
                <p>Economy</p>
                <h4 class="price">$ 980</h4>
                <div class="content-bottom">
                  <a class="btn">Book Now</a>
                  <!-- <a class="discover">Discover</a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-10">
            <div class="row">
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img02.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">New York to California</h2>
                    <span>02 Jun 2024 - 20 Jul 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 290</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">New York to California</h2>
                    <span>02 Jun 2024 - 20 Jul 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 290</h4>
                    <div class="content-bottom">
                      <a class="btn">Book Now</a>
                      <!-- <a class="discover">Discover</a> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img03.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Malaga to Finland</h2>
                    <span>22 Jul 2024 - 16 Sep 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 792</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Malaga to Finland</h2>
                    <span>22 Jul 2024 - 16 Sep 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 792</h4>
                    <div class="content-bottom">
                      <a class="btn">Book Now</a>
                      <!-- <a class="discover">Discover</a> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img04.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Dubai to Maldives</h2>
                    <span>19 Nov 2024 - 25 Dec 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 980</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Dubai to Maldives</h2>
                    <span>19 Nov 2024 - 25 Dec 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 980</h4>
                    <div class="content-bottom">
                      <a class="btn">Book Now</a>
                      <!-- <a class="discover">Discover</a> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img05.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Dubai to New York</h2>
                    <span>05 Oct 2024 - 12 Dec 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 350</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Dubai to New York</h2>
                    <span>05 Oct 2024 - 12 Dec 2024</span>
                    <p>Economy</p>
                    <h4 class="price">$ 350</h4>
                    <div class="content-bottom">
                      <a class="btn">Book Now</a>
                      <!-- <a class="discover">Discover</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- flight-offer-area-end -->

    <!-- destination-area -->
    <section class="destination-area destination-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-8" style="text-align: left">
            <div class="section-title">
              <span class="sub-title">{{ $t("homePageContent.hightlightContent.heading2") }}</span>
              <h2 class="title">{{ $t("homePageContent.hightlightContent.heading3") }}</h2>
            </div>
            <div class="destination-content">
              <!-- <p>Get rewarded for your travels – unlock instant savings of 10% or more with a free <span>Metrotravelservices.com</span> account</p> -->
              <ul class="p-0 flex-md-row flex-column">
                <li>
                  <div class="counter-item">
                    <div class="counter-content">
                      <h2 class="count fw-bold">
                        <span class="odometer" data-count="5830"></span>5830+
                      </h2>
                      <p class="mt-1">Happy Customers</p>
                    </div>
                    <div class="counter-icon mt-0">
                      <!-- <i class="flaticon-group"></i> -->
                      <img src="../assets/img/icon/group.png" width="60px" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="counter-item">
                    <div class="counter-content">
                      <h2 class="count fw-bold">
                        <span class="odometer" data-count="100"></span>100%
                      </h2>
                      <p class="mt-1">Client Setisfied</p>
                    </div>
                    <div class="counter-icon mt-0">
                      <!-- <i class="flaticon-globe"></i> -->
                      <img src="../assets/img/icon/global.png" width="60px" />
                    </div>
                  </div>
                </li>
              </ul>
              <div class="content-bottom flex-md-row flex-column">
                <p>Discover the latest offers & news and start planning</p>
                <a>contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- destination-area-end -->

    <!-- brand-area -->
    <!-- <div class="brand-area brand-bg">
                <div class="container">
                    <div class="row brand-active">
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img01.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img02.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img03.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img04.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img05.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img06.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img03.png" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div> -->
    <!-- brand-area-end -->

    <!-- service-area -->
    <section class="service-area">
      <div class="container" style="text-align: left">
        <div class="row align-items-end mb-50">
          <div class="col-md-8 mb-2">
            <div class="section-title">
              <span class="sub-title">{{ $t("homePageContent.hightlightContent.heading4") }}</span>
              <h2 class="title">{{ $t("homePageContent.hightlightContent.subHead4") }}</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-nav"></div>
          </div>
        </div>
        <div class="row service-active">
          <!-- <div class="col-lg-4" v-for="(data,index) in flightOptions" :key="index">
            <div class="service-item">
              <div class="service-icon">
                <img :src="data.img" alt="" />
              </div>
              <div class="service-content">
                <span>{{data.service}}</span>
                <h2 class="title">{{data.heading}}</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      {{ data.content1 }} <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      {{ data.content2 }}
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      {{ data.content3 }} <i class="fa-solid fa-check"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon01.png" alt="" />
              </div>
              <div class="service-content">
                <!-- <span>Service 01</span> -->
                <h2 class="title">Pre-Book Your Baggage</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      Pre-book your baggage <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Allowance now and save up
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      90% of baggage charges <i class="fa-solid fa-check"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon02.png" alt="" />
              </div>
              <div class="service-content">
                <!-- <span>Service 02</span> -->
                <h2 class="title">Reserve preferred seat!</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      What will it be, window or aisle?
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Select your preferred seat prior
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>Reserved for you. <i class="fa-solid fa-check"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon03.png" alt="" />
              </div>
              <div class="service-content">
                <!-- <span>Service 03</span> -->
                <h2 class="title">Enjoy stress-free travel</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      Travel stress-free by getting<i
                        class="fa-solid fa-check"
                      ></i>
                    </li>
                    <li>
                      Covered for flight modification
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Cancellation, accident & medical
                      <i class="fa-solid fa-check"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- service-area-end -->

    <!-- blog-area -->
    <section class="blog-area blog-bg d-none">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span class="sub-title">our News Feeds</span>
              <h2 class="title">Latest News Update</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center flex-md-row flex-column">
          <div class="col-53">
            <div class="blog-item">
              <div class="blog-thumb">
                <a
                  ><img src="../assets/img/blog/blog_img01.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a >Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a
                    >Depending on your departure point and destination
                    flights</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="col-47">
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a
                  ><img src="../assets/img/blog/blog_img02.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a>Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a
                    >Happy International Country Flight Attendant Day</a
                  >
                </h2>
              </div>
            </div>
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a
                  ><img src="../assets/img/blog/blog_img03.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a>Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a
                    >The US is a Large Country and Climate Varies by Region</a
                  >
                </h2>
              </div>
            </div>
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a
                  ><img src="../assets/img/blog/blog_img04.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a>Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a
                    >But There are Dozen of Low-cost Airlines Including</a
                  >
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- blog-area-end -->
  </main>
  <!-- main-area-end -->
</template>

<script>
import moment from "moment";
import axios from "axios";
// import AutoComplete from "primevue/autocomplete";

// import topDestinations from "../topDestination.json";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
export default {
  components: {
    // multicityCom,
    VueDatePicker,
    // AutoComplete,
    // dataloader
  },

  data() {
    return {
      selectedCity: "Modern",
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],

      countries: [{ name: "Australia", code: "AU" }],

      //   imgItems: [

      //     {
      //       src: require('@/assets/homesl1.png')
      //     },
      //     {
      //       src: require('@/assets/homesl2.png')
      //     },
      //     {
      //       src: require('@/assets/homesl3.png')
      //     },
      //     {
      //       src: require('@/assets/homesl4.png')
      //     },
      //     {
      //       src: require('@/assets/homesl5.png')
      //     },
      //   ],

      // topDestinations:topDestinations,
      currentSlide: 0,
      carouselData: [
        { src: require('../assets/img/images/trbg1.jpg') },
        // { src: require('../assets/img/images/about_img02.jpg') },
        // { src: require('../assets/img/images/trbg1.jpg') }
      ],
      interval: null,

      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      subscribeApi:"",

      radios: "one",
      fromloader:false,
      toloader:false,
      fromlabel: false,
      tolabel: false,
      loader: false,
      dialog1: false,
      dialog2: false,
      dateFormat: "MMM dd",
      sixWeekMode: true,
      classSelect: false,
      subEmail: "",
      subPhonenumber: "",
      multiCalender: true,
      validData: false,
      selectedCountry: "",
      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      name1: "John Doe",
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,
      RecentSearchData: [],
      selectAirports: "",
      red: "1",
      red1: 1,
      rount: true,
      Oneway: false,
      multicity: false,
      // select: "Economy",
      travels: false,
      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      resultData: 1,
      classType: "Economy",
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      isVisible: true,
      // selectAirports: "",
      token: "Tc7RQaX79Hrx5CFK",

      // Autocomplete
      loading: false,
      fromAirports: [],
      airitems: [],
      // Autocomplete End

      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,
      valid: false,
      Errormsg: "",
      agreeMsg: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },

      roundedShadow: false,
      emailBox: false,
      phoneNumber: true,

      subEmailvalid: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      phoneValidate: [
        (v) => /^\+?[0-9]{1,16}$/.test(v) || "Please enter a phone number ",
        // (v) => /^(\+?[0-9]{1,12})?$/.test(v) || "Please enter a valid phone number (up to 12 digits, starting with +)",
      ],

      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ],

      addmore: true,
      clearall: false,

      airbooking: true,
      mytrips: false,
      resentSearch: false,

      oneway: {
        from: null,
        to: null,
        travelDate: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      city: [],
      city1: [],
      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            autocomFromDrop:false,
            autocomToDrop:false,
            selectedFrom:false,
            selectedTo:false
          },
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom:false,
            autocomTo:false,
            autocomFromDrop:false,
            autocomToDrop:false,
            selectedFrom:false,
            selectedTo:false
          },
        ],
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },
      multidrop: [],
      multidrop1: [],
      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Adult: [],
      Child: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      forData: false,
      formres: false,
      formres1: false,
      Infrant: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      loadingData: true,
      dataloaded: false,
      randomAlphabets: "",
      cacheData: "",
      fromRecent: "",
      toRecent: "",
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      loginuser: {
        email: "",
        password: "",
      },
      userName: [(v) => !!v || "Please enter your name"],
      email: [(v) => !!v || "Please enter your Email"],
      password: [(v) => !!v || "Please enter your Password"],
      repassword: [
        (v) => !!v || "Please enter your Re-Password",
        (v) => v === this.deatail.password || "Passwords do not match",
      ],
      pnr: [(v) => !!v || "Please enter your PNR/Booking Ref no"],
      emaillast: [(v) => !!v || "Please enter your Email/Lastname"],

      chooseUs: [
        {
          logo: "fa-solid fa-shield-halved",
          sideHead: "Cheapest Deals",
          chooseLine:
            "Lowest Guaranteed booking price for popular destinations all over the globe.",
        },
        {
          logo: "fa-solid fa-mobile-screen",
          sideHead: "Easy To Book",
          chooseLine:
            "Search for your favorite city, choose the best deal and get ready to travel It is that easy!",
        },
        {
          logo: "fa-solid fa-certificate",
          sideHead: "Trust",
          chooseLine: "Providing 100% Safe and Secure bookings to customers.",
        },
      ],

      //   Destination: [
      //     {
      //       name: "Andaman",
      //       rate: "$480",
      //       url: require('@/assets/andaman.jpg')
      //     },
      //     {
      //       name: "SriLanka",
      //       rate: "$890",
      //       url: require('@/assets/SriLanka.jpg')
      //     },
      //     {
      //       name: "Dubai",
      //       rate: "$2310",
      //       url: require('@/assets/DUBAI.jpg')
      //     },
      //     {
      //       name: "Thailand",
      //       rate: "$760",
      //       url: require('@/assets/Thailand.jpg')
      //     },
      //   ],

      //   service: [
      //     {
      //       url: require('@/assets/cardimg.jpg'),
      //       name: "Best Price Promise and Guarantee",
      //       letter: "Get best fares to destinations worldwide."
      //     },
      //     {
      //       url: require('@/assets/cardimg1.jpg'),
      //       name: "Get Great Deals! ",
      //       letter: "Best fares in 500+ airlines for low airfares!"
      //     },
      //     {
      //       url: require('@/assets/cardimg2.svg'),
      //       name: "Expert Guidance",
      //       letter: "Get personalized assistance from our travel experts."
      //     },
      //   ],
      //   profile: [
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Alex Feder",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Elly Forb",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Mia Nest",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Dan Dorno",
      //     },
      //   ],

      //   slide: [
      //     {
      //       url: require('@/assets/maldives.avif'),
      //       name: "MALDIVES ISLAND - MALDIVES",
      //       letter: "Discover Maldives Island",
      //       rate: "$960"
      //     },
      //     {
      //       url: require('@/assets/taj-mahal.avif'),
      //       name: "TAJ MAHAL - AGRA",
      //       letter: "Historical Architecture",
      //       rate:"$1200"
      //     },
      //     {
      //       url: require('@/assets/paris.jpeg'),
      //       name: "EIFFEL TOWER - PARIS",
      //       letter : "Most Recognizable Monuments",
      //       rate : "$2120"
      //     },
      //     {
      //       url: require('@/assets/Jaipur.avif'),
      //       name: "PINK PALACE - JAIPUR",
      //       letter: "Palace of a Pyramidal shape",
      //       rate: "$2145"
      //     },
      //     {
      //       url: require('@/assets/munnar.avif'),
      //       name : "MUNNAR",
      //       letter : "Popular Resort Town in Kerala",
      //       rate: "$850"
      //     },
      //     {
      //       url: require('@/assets/china.jpg'),
      //       name : "SIKKIM",
      //       letter : "The land of Gorgeous Nature",
      //       rate: "$1550"
      //     }
      //   ],
      //   cardData: [
      //     {
      //       url: require('@/assets/canada.avif'),
      //       name: "CANADA",
      //       letter: "Snow-capped Mountains, Exotic Beaches, Lush Green Forests and Pristine Lakes.",
      //       rate: "$960"
      //     },
      //     {
      //       url: require('@/assets/baliimg.avif'),
      //       name: "BALI",
      //       letter: "Green Mountains, Rice Field Terraces, and Cool Villages",
      //       rate:"$1200"
      //     },
      //     {
      //       url: require('@/assets/dubaiimg.avif'),
      //       name: "DUBAI",
      //       letter : "Stunning Architecture, Majestic Skyscrapers, Glittering Skylines",
      //       rate : "$2120"
      //     },
      //     {
      //       url: require('@/assets/san.avif'),
      //       name: "SAN FRANCISCO",
      //       letter: " Famous for Grand-dame Victorians, a Soaring Golden Bridge",
      //       rate: "$2145"
      //     },
      //     {
      //       url: require('@/assets/parisimg.avif'),
      //       name : "PARIS",
      //       letter : "Paris is known for its Gorgeous, Imposing Monuments.",
      //       rate: "$850"
      //     },
      //     {
      //       url: require('@/assets/cardimg3.avif'),
      //       name : "CHITKUL - HIMACHAL PRADESH",
      //       letter : "Hub of Beautiful Lakes hold Cultural and Religious Significance.",
      //       rate: "$920"
      //     }
      //   ],

      classTypeitems: [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
        {
          text: this.$t("formsContents.options.premiumEconomy"),
          value: "Premium Economy",
          department: "Premium Economy Department",
        },
      ],

      airlineIcao: {},

      // uniqueId:[],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      focusedElement: null,
      changeTextFrom: "",
      changeTextTo: "",
      focusEvent1: false,
      focusEvent2: false,
      focusEvent3: false,
      dateUnfocus: false,
      closeDateMenu: false,
      bookid: "",
      lastname: "",
      focusFalse: true,
      autocompleteApi:"",
      pluginWhatspp: false,
      whatsappApi: "",
      selectedFrom:false,
      selectedTo:false,
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
    this.stopAutoplay();
  },

  methods: {
    startAutoplay() {
      this.interval = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.carouselData.length;
      }, 5000); // Change background every 5 seconds
    },
    stopAutoplay() {
      clearInterval(this.interval);
    },
    clickFocus(){
        const regex = /[-()]/;
        if(!regex.test(this.oneway.from)){
            this.$refs.fromAutoComplete.focus();
        }
    },
    clickFocusTo(){
        const regex = /[-()]/;
        if(!regex.test(this.oneway.to)){
            this.$refs.toAutoComplete.focus();
        }
    },
    clickMultiFrom(index){
        this.multiData.itinery.map((v,i) => {
            if(i == index){
                const regex = /[-()]/;
                if(!regex.test(v.from)){
                    this.$refs.multiFromAutocomplete[index].focus();
                }
            }
        })
    },
    clickMultiTo(index){
        this.multiData.itinery.map((v,i) => {
            if(i == index){
                const regex = /[-()]/;
                if(!regex.test(v.to)){
                    this.$refs.multiToAutocomplete[index].focus();
                }
            }
        })
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getrecent(data) {
      // console.log(data, 'pppp')
      this.oneway.from = data.from.name;
      this.fromRecent = data.from;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.oneway.to = data.to.name;
      this.toRecent = data.to;
      //
    },
    getdated($event) {
      return moment($event).format(" DD-MMM-YYYY");
    },
    // getrecentData(data) {
    //   console.log(data, "ahahakkaaj");
    //   let res = data.split(",");
    //   let result = "";
    //   res.forEach((v, i) => {
    //     if (i !== res.length - 1) result += v + ",";
    //   });
    //   return result.slice(0, -1);
    // },

     swapFromTo() {
      const temp = this.oneway.from;
      // this.inputCity = this.inputCityto;
      // this.inputCityto = temp;
      this.oneway.from = this.oneway.to;
      this.oneway.to = temp
    },

    async typeCity(event, $data) {
    if(event.length < 3 && $data == 1){
      this.maximumletter = true;
    }
    else{
      this.maximumletter = false;
    }
    if(event.length < 3 && $data == 2){
      this.maximumletter1 = true;
    }
    else {
      this.maximumletter1 = false;
    }
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
          .get(
            `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
            }

            // let airlineIcao = response.data;
            // if (airlineIcao) {
            //   Object.keys(airlineIcao).forEach((icao) => {
            //     const airport = airlineIcao[icao];

            //     if (airport.iata !== "") {
            //       this.fromAirports.push({
            //         iata: airport.iata,
            //         icao: airport.icao,
            //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
            //       });
            //     }
            //   });
            // }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        // const searchString = event.toUpperCase();

        // setTimeout(() => {
        //   const iataMatch = [];
        //   const otherMatches = [];
        //   this.fromAirports.forEach((e) => {
        //     const iata = e.iata ? e.iata.toUpperCase() : "";
        //     const placesLowerCase = e.places.toLowerCase();
        //     if (iata === searchString) {
        //       iataMatch.push(e);
        //     } else if (placesLowerCase.includes(searchString.toLowerCase())) {
        //       otherMatches.push(e);
        //     }
        //   });

        //   this.airitems = iataMatch.concat(otherMatches);

        //   $event = this.airitems.map((item) => {
        //     const parts = item.places.split(", ");
        //     if (parts.length >= 2) {
        //       const name = `${parts[1]} (${parts[0]})`;
        //       const originalName = item.places;
        //       return {
        //         name,
        //         originalName,
        //       };
        //     }
        //   });

        //   if ($data == 1) {
        //     this.fromloader = false;
        //     $event.forEach((v) => {
        //       if (
        //         !this.city.some((item) => item.originalName == v.originalName)
        //       ) {
        //         this.city.push(v);
        //       }
        //     });
        //     this.typedrop = true;
        //   } else if ($data == 2) {
        //     this.toloader = false;
        //     $event.forEach((v) => {
        //       if (
        //         !this.city1.some((item) => item.originalName == v.originalName)
        //       ) {
        //         this.city1.push(v);
        //       }
        //     });
        //     this.typedrop1 = true;
        //   }

        //   console.log(this.city, this.city1, "vasanth");
        //   this.loading = false;
        // }, 1000);
      }
    },

    // generateRandomAlphabets() {
    //   const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //   let result = '';

    //   for (let i = 0; i < 6; i++) {
    //     const randomIndex = Math.floor(Math.random() * alphabets.length);
    //     result += alphabets[randomIndex];
    //   }

    //   this.randomAlphabets = result;
    //   this.updateCanvas();
    // },
    // updateCanvas() {
    //   const canvas = this.$refs.myCanvas;
    //   const ctx = canvas.getContext('2d');
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.font = '20px Arial';
    //   ctx.fillStyle = "white";
    //   ctx.fillText(this.randomAlphabets, 10, 40);
    // },
    // generate() {
    //   this.generateRandomAlphabets();
    // },

    deleteItin(index) {
      this.multiData.itinery.splice(index, 1);
    },

    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loadingData = false;
      } else {
        this.loadingData = true;
      }
    },
    subData($data) {
      console.log($data, "tytttt");
      if ($data.includes("com")) {
        document.getElementById("visible").style.visibility = "visible";
        this.dataloaded = true;
      } else {
        this.dataloaded = false;
      }
    },

    focusAutocomplete(element) {
      this.focusedElement = element;

      if (this.focusedElement === "from") {
        this.formres = true;
        this.formres1 = false;

        this.focusEvent1 = true;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.$refs.fromAutoComplete.$el.querySelector("input").focus();
      } else if (this.focusedElement === "to") {
        this.formres1 = true;
        this.formres = false;
        this.focusEvent3 = false;
        this.focusEvent1 = false;
        this.focusEvent2 = false;

        this.$nextTick(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        });
      }
    },

    focusMulti_AutoComplete(element, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (!this.focusFalse) {
            this.focusedElement = null;
            v.focusMultiEvent3 = false;
          } else {
            this.focusedElement = element;
          }
        }
      });

      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          v.focusMultiEvent3 = false;
          console.log(v.focusMultiEvent3, "checkcheckcheck");
        }
      });

      if (this.focusedElement === "from") {
        this.multiData.itinery.map((v, i) => {
          // console.log(v,i,index,'mimimimi...1.')
          if (i == index) {
            v.formres = true;
            v.formres1 = false;

            v.focusMultiEvent1 = true;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
            const inputElement =
              this.$refs.fromAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement === "to") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = true;
            v.focusMultiEvent3 = false;

            const inputElement =
              this.$refs.secondAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement == "calendar1") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = true;

            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
            // v.$refs.fromAutoComplete.$el.querySelector("input").focus();

            // v.$nextTick(() => {
            //   this.$refs.secondAutoComplete.$el.querySelector("input").focus();
            // })
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      }
    },

    focusCalendar(element) {
      if (!this.focusFalse) {
        this.focusedElement = null;
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
        this.$refs.dpRef1.closeMenu();
      } else {
        this.focusedElement = element;
      }

      if (this.focusedElement == "calendar1") {
        this.$nextTick(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent3 = true;
          this.focusEvent2 = false;
          this.focusEvent1 = false;
          this.formres = false;
          this.formres1 = false;
        });
      } else {
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
      }
    },

    handleOutsideClick(event) {
      if (!event.target.closest(".input-styles")) {
        this.typedrop = false;
        this.typedrop1 = false;
        this.formres = false;
        this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.multiData.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3")
          v.focusMultiEvent1 = false;
          v.focusMultiEvent2 = false;
          v.focusMultiEvent3 = false;
          // this.multiData.itinery[i].autocomfrom = false;
          // this.multiData.itinery[i].autocomTo = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },
    // subRadio() {

    //   this.phoneNumber = !this.phoneNumber
    // },
    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },
    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },

    act(data) {
      this.red1 = data;
      //console.log(this.red1, '00')
      this.oneway.city = this.red1;
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.radios = 'two'
      }
      if (data == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.radios = 'one'
      }
      if (data == 3) {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        this.radios = 'three'
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");
      // console.log(this.result, "lavs");
      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;

      this.aduls = result1.adult;

      this.aduls2 = result1.infrant;

      this.aduls1 = result1.child;

      this.select = result1.class;
    },

    passengerData() {
      this.resultData = this.aduls + this.aduls1 + this.aduls2;
    },
    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }

      // if(this.multiData.itinery.length-1){
      //   this.multiSearchBtn = true
      // }

      if (this.multiData.itinery.length > 3) {
        this.clearall = true;
      }

      this.multiData.itinery.map((v, i) => {
        // console.log(i, v, "indexooo");

        if (this.multiData.itinery.length - 2 == i) {
          this.multiData.itinery[i + 1].from = this.multiData.itinery[i].to;
          if(this.multiData.itinery[i + 1].from != null){
            this.multiData.itinery[i + 1].selectedFrom = true;
          }
          // this.$refs.multiToAutocomplete[i+1].focus()
        }
      });

      // for (let i = 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }

      // for (let i = 0; i <= this.multiData.length - 1; i++) {
      //     //console.log(this.multiData.length,'this.multiData.length')
      //     if(this.multiData.length==2){
      //       this.multiData[1].from = this.multiData[0].to
      //     }
      //     else if(this.multiData.length==3){
      //       this.multiData[2].from = this.multiData[1].to
      //     }
      //     else if(this.multiData.length==4){
      //       this.multiData[3].from = this.multiData[2].to
      //     }
      //   }
      //console.log(this.multiData, 'ooooo')
    },

    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },

    deg1() {
      this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1;
        //console.log(this.aduls, '1111')

        this.adulted();
        this.added();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        this.Childed1();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;
        //console.log(this.aduls1, '2222')

        this.Childed();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;
        //console.log(this.aduls2, '3333')

        this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      this.passengerData();
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
      this.travels = false;
    },
     selectClasstype(item) {
      this.classType = item.value;
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // if (!this.classSelect) {
      //   this.$refs.countrySelect.focus();
      // }
    },

    closeDropdown() {
      this.travels = false;
      this.$refs.countrySelect.blur();
      // this.$refs.mySelect.blur();
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;
      // this.$refs.dpRef2.openMenu();
      this.focusedElement = "calendar2";

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
        this.$refs.dpRef1.openMenu();
      }
      if(!this.oneway.redate){
        this.$refs.dpRef2.openMenu();
      }
      else{
        this.$refs.dpRef1.openMenu();
      }
    },
    toDateChanged(newValue){
       this.oneway.redate = newValue;
      // this.$refs.dpRef2.openMenu();
      // this.focusedElement = "calendar2";

      if (this.oneway.redate) {
        this.clicked1 = true;
        this.toDateError = false;
      } else {
        this.clicked1 = false;
        this.toDateError = true;
        this.$refs.dpRef2.openMenu();
    }
    },

    clearDate() {
      this.oneway.dedate = null;
      this.oneway.travelDate = null;
      this.focusEvent3 = false;
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();
      // console.log(v.clicked3,"checking....")

      if (this.oneway.dedate) {
        this.clicked1 = true;
        // this.fromlabel=true
      } else {
        this.clicked1 = false;
        // this.fromlabel=false;
      }
    },
    ClickOutDepatMulti(){
      // console.log(this.$refs.dpRef4,'depRef4depRef4')
      
      this.multiData.itinery.forEach((v,i) => {
        this.$refs.dpRef4[i].closeMenu();
        if(v.departDate){
          v.clicked3 = true;
        }
        else{
          v.clicked3 = false
        }
      })
    },

    // toDateChanged(newValue) {
    //   this.oneway.redate = newValue;

    //this.$refs.countrySelect.focus();

    // let inputField = this.$refs.countrySelect
    // if(inputField){
    //   inputField.menu=true
    // }
    // this.$refs.countrySelect.openMenu();
    // console.log(this.$refs.countrySelect,'this.$refs.countrySelectthis.$refs.countrySelect')

    // if (this.oneway.redate) {
    //   this.clicked2 = true;
    //   this.toDateError = false;
    //   this.focusedElement = null
    // }
    // else {
    //   this.clicked2 = false;
    //   this.toDateError = true;
    //   this.$refs.dpRef2.openMenu();
    // }
    // },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;
      //console.log(this.oneway.dedate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker


    fromInputData(){
      this.oneway.from = ""
      if(!this.oneway.from){
        this.selectedFrom = false;
        this.$refs.fromAutoComplete.focus();
      }
      // const regex = /[-()]/;
      // if(regex.test(this.oneway.from)){
      //   this.selectedFrom = true;
      // }
      // if(!this.oneway.from || !this.inputCity){
      //    this.$refs.firstAutoComplete.focus();
      // }
    },
    toInputData(){
      this.oneway.to = ""
      if(!this.oneway.to){
        this.selectedTo = false;
        this.$refs.toAutoComplete.focus();
      }
      // if(!this.oneway.to || !this.inputCityto){
      //    this.$refs.secondAutoComplete.focus();
      // }
      // this.$refs.secondAutoComplete.focus();
    },

    multiDateChanged(newValue, index) {
      // this.multiData.itinery.map((v, i) => {
      //   if (index == i) {
      //     if (v.departDate != null) {
      //       v.multiDateError = false;
      //       v.focusMultiEvent3 = false;
      //     } else {
      //       v.multiDateError = true;
      //       v.focusMultiEvent3 = true;
      //     }
      //     console.log(v,i,'multiDateChangedmultiDateChanged')
      //   }
      // });

      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (v.departDate) {
              const inputElement =
                this.$refs.multiToAutocomplete[index+1];
              if (inputElement) {
                inputElement.focus();
              }
          } else {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
          }
        }
      });


       this.multiData.itinery[index].departDate = newValue;
      if (newValue) {
        this.multiData.itinery[index].multiDateError = false;
        this.multiData.itinery[index].focusMultiEvent3 = false;
      } else {
        this.multiData.itinery[index].multiDateError = true;
        this.multiData.itinery[index].focusMultiEvent3 = true;
      }

      console.log(this.multiData.itinery[index], `Updated itinerary at index ${index}`);

      this.multiData.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].departDate;
      } else {
        return `${new Date()}`;
      }
    },

    multiClickOutside() {
      this.input.clicked3 = false;
      this.datePickerIsOpen = false;
    },
      cardassigndata($event) {
      console.log($event, "$event4444");
      this.resentSearch = false;
      this.airbooking = true;
      this.oneway.from = $event.from;
      this.oneway.to = $event.to;
      this.selectedFrom = true;
      this.selectedTo = true;
      this.aduls = $event.adult;
      this.aduls1 = $event.child;
      this.aduls2 = $event.inf;
      this.classType = $event.class;
      this.oneway.dedate = $event.fromDate;
      this.oneway.redate = $event.toDate;

      this.act($event.city);
      // this.updateRecentSearch($event)

      if ($event.itinery) {
        console.log($event, '$event1111111');
        this.aduls = $event.adult
        this.aduls1 = $event.child
        this.aduls2 = $event.infrant
        
        this.multiData = {
          adult: $event.adult,
          child: $event.child,
          infrant: $event.infrant,
          class: $event.class,
          city: $event.city,
          itinery: $event.itinery.map(itin => ({
            ...itin,
            departDate: itin.depdata,
            selectedFrom:true,
            selectedTo:true
          }))
        };

        this.handleMultiSearch(this.multiData);

        console.log(this.multiData, 'this.multiData..11');
      }
      else{
        this.handleRecentSearch($event);
      }
    },
      recentFromData() {
        let result1 = JSON.parse(localStorage.getItem("recentFrom")) || [];
        let today = new Date();

        const filteredResults = result1.filter(item => {
          if (item.itinery && item.itinery.length > 0) {
            for (let itineraryItem of item.itinery) {
              let depDate = new Date(itineraryItem.depdata);
              if (depDate < today) {
                return false;
              }
            }
          } else {
            let fromDate = new Date(item.fromDate);
            if (fromDate < today) {
              return false;
            }
          }
          return true;
        });

        localStorage.setItem("recentFrom", JSON.stringify(filteredResults));

        this.RecentSearchData = filteredResults.slice(0, 15);
      },

    handleMultiSearch(newMultiData) {
      let storedRecentFrom = JSON.parse(localStorage.getItem("recentFrom")) || [];

      storedRecentFrom = storedRecentFrom.filter(existingItem => {
          const existingItinery = existingItem.itinery || [];

          const isDuplicate = newMultiData.itinery.every(newItin => 
              existingItinery.some(existingItin =>
                  newItin.from === existingItin.from &&
                  newItin.to === existingItin.to &&
                  newItin.depdata === existingItin.depdata
              )
          );

          const isAdditionalPropsMatch = (
              existingItem.adult === newMultiData.adult &&
              existingItem.child === newMultiData.child &&
              existingItem.infrant === newMultiData.infrant &&
              existingItem.class === newMultiData.class &&
              existingItem.city === newMultiData.city
          );

          return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },


     handleRecentSearch(data) {
        let recentSearchObj;

        let isOneway = this.oneway.city == '2' ? true : false

        if (data) {
          recentSearchObj = {
            from: data.from || '',
            to: data.to || '',
            fromDate: data.fromDate || '',
             toDate: isOneway ? null : data.toDate || null,
            adult: data.adult || 0,
            child: data.child || 0,
            inf: data.inf || 0,
            city: data.city || '',
            class: data.class || ''
          };
          console.log('Using provided data:', recentSearchObj);
        } else {
          recentSearchObj = {
            from: this.oneway.from || '',
            to: this.oneway.to || '',
            fromDate: this.oneway.dedate || '',
            toDate: isOneway ? null : this.oneway.redate || null,
            adult: this.aduls || 0,
            child: this.aduls1 || 0,
            inf: this.aduls2 || 0,
            city: this.red1 || '',
            class: this.classType || ''
          };
          console.log('Using current state values:', recentSearchObj);
        }

        let storedRecentFrom = JSON.parse(localStorage.getItem("recentFrom")) || [];
        console.log('Stored recent searches:', storedRecentFrom);

        storedRecentFrom = storedRecentFrom.filter((search) => {
          const searchFromDate = new Date(search.fromDate).toDateString();
          const searchToDate = new Date(search.toDate).toDateString();
          const recentFromDate = new Date(recentSearchObj.fromDate).toDateString();
          const recentToDate = new Date(recentSearchObj.toDate).toDateString();

          return !(
            search.from === recentSearchObj.from &&
            search.to === recentSearchObj.to &&
            searchFromDate === recentFromDate &&
            searchToDate === recentToDate &&
            search.adult === recentSearchObj.adult &&
            search.child === recentSearchObj.child &&
            search.inf === recentSearchObj.inf &&
            search.city === recentSearchObj.city &&
            search.class === recentSearchObj.class
          );
        });

        storedRecentFrom.unshift(recentSearchObj);
        console.log('Updated search list:', storedRecentFrom);

        // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
        return storedRecentFrom;
      },

      validateRountData() {
        const regex = /[-()]/;
        let fromData = this.oneway.from;
        let toData = this.oneway.to

        // console.log(fromData,toData,'validateDatasssss....4')

        const fromInvalid = fromData != null && regex.test(fromData);
        const toInvalid = toData != null && regex.test(toData);
        const dedateInvalid = this.oneway.dedate != null;
        const redateInvalid = this.oneway.redate != null;

        // console.log(fromData != null, regex.test(fromData), 'validateDatasssss...1');
        // console.log(fromInvalid, toInvalid, dedateInvalid, redateInvalid, 'validateDatasssss');

        if (fromInvalid && toInvalid && dedateInvalid && redateInvalid) {
          return true; 
        }
        
        return false; 
      },
      validateOnewayData(){
        const regex = /[-()]/;
        let fromData = this.oneway.from;
        let toData = this.oneway.to

        // console.log(fromData,toData,'validateDatasssss....4')

        const fromInvalid = fromData != null && regex.test(fromData);
        const toInvalid = toData != null && regex.test(toData);
        const dedateInvalid = this.oneway.dedate != null;

        // console.log(fromData != null, regex.test(fromData), 'validateDatasssss...1');
        // console.log(fromInvalid, toInvalid, dedateInvalid, redateInvalid, 'validateDatasssss');

        if (fromInvalid && toInvalid && dedateInvalid) {
          return true; 
        }
        
        return false; 
      },


    sended() {
      this.focusFalse = false;
      // this.inputCity =
      //   this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      // this.inputCityto =
      //   this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
      console.log(this.focusEvent3, "focusEvent3focusEvent3");

      this.$nextTick(() => {
        this.focusEvent3 = false;
        this.$refs.dpRef1.closeMenu();
        console.log(this.focusEvent3, "this.focusEvent3this.focusEvent3");
      });

      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      // console.log(this.oneway, 'oneway.classoneway.class')

      let validateData = this.validateRountData()
      console.log(validateData,'validateDatasssss...2')
      if (validateData) {
        setTimeout(() => {
          // this.$refs.dpRef1.closeMenu();
          this.focusEvent3 = false;
          // console.log(this.$refs.dpRef1, 'fdfdfdfdfdf...1')
        }, 5);

          this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
          this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
       

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else {
          this.roundto = this.oneway.to;
        }
      
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        console.log(rountdetail, "rountdetailrountdetail");
        const resultData = this.handleRecentSearch()

        localStorage.setItem('recentFrom',JSON.stringify(resultData))

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        // alert("Please Enter the Details");
        this.validateOnewayForm();
      }
    },

    bookinglist() {
      if (this.valid) {
        this.overlay = true;
        // this.retreivebooking.booking_id = this.bookid;
        // this.retreivebooking.last_name = this.lastname;

        let obj = {
          booking_Id: this.bookid,
          last_name: this.lastname,
        };

        let booking_details = JSON.stringify(obj);

        setTimeout(() => {
          this.dialog3 = true;
          this.overlay = false;
          this.$router.push({
            path: "/bookingView",
            query: { booking_id: booking_details },
          });
        }, 2000);
      }
    },
  
    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      // this.inputCity =
      //   this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      // this.inputCityto =
      //   this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
         this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

      let validateData = this.validateOnewayData()
      console.log(validateData,'validateDatasssss...4')

      if (validateData) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;

        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);
        let fromData = "";
        let toData = "";

        if (this.oneway.from.name) {
          fromData = this.oneway.from.name;
        } else {
          fromData = this.oneway.from;
        }

        if (this.oneway.to.name) {
          toData = this.oneway.to.name;
        } else {
          toData = this.oneway.to;
        }


        // console.log(this.oneway, ' this.oneway this.oneway this.oneway this.oneway this.oneway')
        let rountdetail = {
          from: fromData,
          to: toData,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };

          const resultData = this.handleRecentSearch()
          localStorage.setItem('recentFrom', JSON.stringify(resultData))

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },
    onewaySwap() {
      this.swapData = !this.swapData;
      let $dataFrom = this.inputCity;
      let $dataTo = this.inputCityto;
      this.inputCity = $dataTo;
      this.inputCityto = $dataFrom;
    },

    datachange(data) {
      console.log(data, "pppp");
      this.oneway.from = `${data.city} (${data.iata})`;
      // this.inputCity = this.oneway.from;
      this.typedrop = false;
      const regex = /[-()]/;
      if(regex.test(this.oneway.from)){
        this.selectedFrom = true;
      }
      if(!this.oneway.to){
        this.$refs.toAutoComplete.focus();
      }
      else{
        this.$refs.fromAutoComplete.focus();
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      // this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
      const regex = /[-()]/;
      if(regex.test(this.oneway.to)){
        this.selectedTo = true;
      }
      if(!this.oneway.dedate){
        this.$refs.dpRef1.openMenu();
      }
      else{
        this.$refs.toAutoComplete.focus();
      }
    },
    multiFrom(index){
    //   const regex = /[-()]/;
       this.multiData.itinery.map((v, i) => {
        if(i==index){
            v.from = ""
            v.selectedFrom = false
            this.$refs.multiFromAutocomplete[index].focus();
          }
        //   else if(regex.test(v.from)){
        //     v.selectedFrom = true
        // }
       })
    },
    multiTo(index){
    //   const regex = /[-()]/;
       this.multiData.itinery.map((v, i) => {
        if(i==index){
            v.to = ""
            v.selectedTo = false
            this.$refs.multiToAutocomplete[index].focus();
          }
        //   else if(regex.test(v.from)){
        //     v.selectedTo = true
        // }
       })
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.multiFromError = false;
          v.selectedFrom = true
        //   if(!v.from){
        //     this.$refs.multiFromAutocomplete[index].focus()
        //   }
          if (!v.to) {
              const inputElement =
                this.$refs.multiToAutocomplete[index];
              if (inputElement) {
                inputElement.focus();
              }
          } else if(v.from) {
              const inputElement =
                this.$refs.multiFromAutocomplete[index];
              console.log(this.$refs.fromAutoComplete);
              if (inputElement) {
                inputElement.blur();
              }
          }
        }
      });
      console.log(this.multiData, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.multiToError = false;
          v.selectedTo = true;
          if(!v.to){
            this.$refs.multiToAutocomplete[index].focus();
          }
          if(!v.departDate){
            const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              else {
                const inputElement = this.$refs.multiFromAutocomplete[index];
                if (inputElement) {
                  inputElement.blur();
                }
              }
          }
          if (this.multiData.itinery.length - 1 != index){
            this.multiData.itinery[i + 1].from = this.multiData.itinery[i].to;
            this.multiData.itinery[i + 1].multiFromError = false;
            this.multiData.itinery[i + 1].selectedFrom = true;
          }
        }
      });

      // for (let i = 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }
      console.log(this.multiData, "this.multiDatathis.multiData");
    },
   validateItinerary() {
    const regex = /[-()]/; // Define the regex to validate 'from' and 'to'
    let isValid = true;    // Assume everything is valid initially

    // Iterate over each itinerary and check if required fields are valid
    for (let i = 0; i < this.multiData.itinery.length; i++) {
      const itinerary = this.multiData.itinery[i];

      // Check if 'from', 'to', and 'departDate' are null or invalid
      const fromInvalid = itinerary.from !== null || regex.test(itinerary.from);
      const toInvalid = itinerary.to !== null || regex.test(itinerary.to);
      const departDateInvalid = itinerary.departDate !== null;

      console.log(`Itinerary ${i}: fromInvalid=${fromInvalid}, toInvalid=${toInvalid}, departDateInvalid=${departDateInvalid}`);

      // If any validation fails, mark isValid as false
      if (fromInvalid || toInvalid || departDateInvalid) {
        isValid = false;
      }
    }

    return isValid; // Return true if all itineraries are valid, otherwise false
  },


    sendedMulticity() {
      this.focusFalse = false;
      console.log(this.multiData, " this.multiData this.multiData");
      let validateData = this.validateItinerary()
      console.log(validateData,'validateMultiCityyyy')
      if (
        this.multiData.itinery[0].from &&
        this.multiData.itinery[0].to &&
        this.multiData.itinery[0].departDate &&
        this.multiData.itinery[1].from &&
        this.multiData.itinery[1].to &&
        this.multiData.itinery[1].departDate &&
        (!this.multiData.itinery[2] ||
          (this.multiData.itinery[2].from &&
            this.multiData.itinery[2].to &&
            this.multiData.itinery[2].departDate)) &&
        (!this.multiData.itinery[3] ||
          (this.multiData.itinery[3].from &&
            this.multiData.itinery[3].to &&
            this.multiData.itinery[3].departDate))
      ) {
        this.multiData.adult = this.aduls;
        this.multiData.child = this.aduls1;
        this.multiData.infrant = this.aduls2;
        this.multiData.class = this.classType;
        let $data = [];
        this.multiData.itinery.forEach((v) => {
          console.log(v, "multy");
          let itineraryObject = {
            from: v.from.name || v.from,
            to: v.to.name || v.to,
            depdata: moment(v.departDate).format("DD-MMM-YYYY"),
          };
          $data.push(itineraryObject);
        });
        console.log($data, " $data $data");

        this.multiData.itinery = $data;

        console.log(this.multiData, "this.multiData");

        const resultData = this.handleMultiSearch(this.multiData)
        localStorage.setItem("recentFrom", JSON.stringify(resultData));

        let muti_city = JSON.stringify(this.multiData);
        this.$router.push({ path: "/search", query: { muti_city } });
      } else {
        // alert("please enter your detail");
        // this.validateOnewayForm();
        this.validateMultiForm();
      }
    },

    // sendedMulticity() {

    //   for (let i = 0; i < this.input.length; i++) {

    //     if (this.input[i].from) {
    //       this.input[i].multiFromError = false;
    //     } else {
    //       this.input[i].multiFromError = true;
    //     }

    //     if (this.input[i].to) {
    //       this.input[i].multiToError = false;
    //     } else {
    //       this.input[i].multiToError = true;
    //     }

    //     if (this.input[i].departDate) {
    //       this.input[i].multiDateError = false;
    //     } else {
    //       this.input[i].multiDateError = true;
    //     }
    //   }

    // },

    // submit() {
    //   //console.log(this.valid, '9999')
    //   if (this.valid) {
    //     loginService.createUser(this.deatail).then(res => {
    //       //console.log(res, '88888')
    //     })
    //   }
    // },

    login() {
      let user1 = this.customer.findIndex(
        (v) => v.email === this.loginuser.email
      );
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(
        (v) => v.password === this.loginuser.password
      );
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true;
        this.buton = false;
      } else {
        this.avath = false;
        this.buton = true;
        this.error = true;
      }
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = false;
          } else {
            v.multiToError = true;
            // v.maximumletter1 = true
          }
        }
      });

      // for (let i = 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }
    },

    // console.log($data, index,'$data, index$data, index')
    // let data1=$data
    // for(let i=0;i<$data.length;i++){
    //     console.log(data1[i],'v[i].departDate')
    //     for(let j=0;j<data1.length;j++){
    //      if($data[i].departDate > data1[j].departDate || $data[i].departDate > data1[j].departDate){
    //        return $data[i-1].departDate=null
    //        console.log(data1[j].departDate,'data1[j].departDatedata1[j].departDate')
    //      }
    //     }
    // }

    // $data.forEach((v,i)=>{
    //     console.log(v[i],'v[i].departDate')
    //     if(v[i].departDate > v[i+1].departDate){

    //        return $data.departDate.splice(v[i],6)
    //     }
    //     else{
    //         return v[i].departDate
    //     }
    // })

    //      if (index == 0 && (($data[index].departDate > $data[index + 1].departDate) || (!$data[index + 1].departDate))) {
    //         console.log("Partially WOrkinggg...1.")
    //         $data[index + 1].departDate = null
    //     }

    //     else if ($data[index].departDate > $data[index + 1].departDate) {
    //             $data[index + 1].departDate = null
    //             console.log("Partially WOrkinggg...2.")
    //         $data[index].departDate.splice()
    //     }

    //     else{
    //     console.log('Not workinfgkkkk')
    // }

    // getdate1($data, index) {
    //     if (index > 0) {
    //         return $data[index - 1].departDate
    //     }
    //     else {
    //         return `${new Date}`
    //     }
    // },

    // multiClickOutside(index) {
    //     console.log(index,"checking....")

    //     this.multiData.clicked3 = false
    //     this.datePickerIsOpen = false;

    //     this.multiData.map((v, i) => {
    //         if (index == i) {
    //             console.log(i,v,"checking...1.")
    //             this.$refs.dpRef4.closeMenu();
    //         }
    //     })
    // },

    multiFromClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;
            if (!v.from) {
              v.multiFromError = true;
            } else {
              v.multiFromError = false;
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;
            if (!v.to) {
              v.multiToError = true;
            } else {
              v.multiToError = false;
            }
          }
        }
      });
    },

    multifocus1(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.to) {
            setTimeout(() => {
              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.fromAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    multifocus2(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.departDate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }

          // for (let i = 0; i < 1; i++) {
          //   console.log(this.multiData[0].to, 'bfbfbfbf.....')
          //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
          // }
        }
      });

      // setTimeout(() => {
      //   if (this.$refs.dpRef4.length > 0) {
      //     const inputElement = this.$refs.dpRef4[index];
      //     if (inputElement) {
      //       inputElement.openMenu();
      //     }
      //   }

      // for (let i = 0; i < 1; i++) {
      //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
      //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
      // }
    },

    // Subcribe Section
    subscribeData() {
      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: true,
        phone_no: this.subPhonenumber,
        token: "djfkdjkf",
      };
      console.log(subscribeData, "subscribeDatasubscribeData");
      if (this.validData) {
        this.loader = true;
        axios
          .post(this.subscribeApi, subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            this.dialog2 = true;
          })
          .catch((error) => {
            console.log(error, "error");
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End

    // fromOutsideAuto($event){
    //   console.log($event.from, 'p.....')
    //   if ($event.from) {
    //     this.fromlabel=true
    //   }
    //   else{
    //   this.fromlabel=false
    //   }
    // },

    // toOutsideAuto($event){
    //   console.log($event.to, 'p.....')
    //   if ($event.to) {
    //     this.tolabel=true
    //   }
    //   else{
    //   this.tolabel=false
    //   }
    // },

    // AutoComplete Functions

    fromClick($event) {
      this.formres = true;
      this.fromlabel = true;
      this.formres1 = false;
      //console.log($event, 'p.....')
      if ($event.from) {
        $event.from = null;
        this.fromErrorMessage = true;
        this.changeTextFrom = false;
      }
    },

    toClick($event) {
      this.tolabel = true;
      this.formres = false;
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null;
        this.toErrorMessage = true;
        this.changeTextTo = false;
      }
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
            console.log(this.oneway.from,this.inputCity,'errorMessage...1')
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
             console.log('errorMessage...2')
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },
    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multiData.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multiData.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          // for (let i = 1; i < this.multiData.itinery.length; i++) {
          //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          // }
        }

        await axios
          .get(
            `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multiData.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multiData.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    validateOnewayForm() {
      const regex = /[-()]/;
      if (this.oneway.from && regex.test(this.oneway.from)) {
        this.fromErrorMessage = false;
      } else {
        this.oneway.from = ""
        this.fromErrorMessage = true;
      }

      if (this.oneway.to && regex.test(this.oneway.to)) {
        this.toErrorMessage = false;
      } else {
        this.oneway.to = ""
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else if(!this.oneway.redate && this.rount == true) {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },
    validateMultiForm(){
      // let dateError = true;
      console.log('validateMultiformError')
      this.multiData.itinery.forEach((data, index) => {
        if (!data.departDate) {
          this.multiData.itinery[index].multiDateError = true;
          // dateError = false;
        } else {
          this.multiData.itinery[index].multiDateError = false;
        }
        if(!data.from){
           this.multiData.itinery[index].multiFromError = true;
        }
        else{
           this.multiData.itinery[index].multiFromError = false;
        }
        if(!data.to){
          this.multiData.itinery[index].multiToError = true;
        }
        else{
          this.multiData.itinery[index].multiToError = false;
        }
      });
    },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios
          .get(
            `${this.autocompleteApi}?airportString=${event.query}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "responseautocomple");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from.length < 3) {
          this.maximumletter = true;
          // this.maximumletter1 = false;
        }
        else{
          this.maximumletter = false
        }
        if (this.oneway.to.length < 3) {
          this.maximumletter1 = true;
        }
        else{
          this.maximumletter1 = false;
        }
      }
    },

    focus1() {
      if (!this.oneway.to) {
        // console.log('testueeiekek.....1')
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        // console.log('testueeiekek.....2')
        setTimeout(() => {
          this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus2() {
      if (!this.oneway.dedate || !this.oneway.travelDate) {
        setTimeout(() => {
          this.focusEvent3 = true;
          if (this.$refs.dpRef1) {
            this.$refs.dpRef1.openMenu();
          }
        }, 100);
      } else {
        this.focusEvent3 = false;

        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          //console.log(this.input[0].to, 'bfbfbfbf.....')
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth <= 480) {
        // console.log(this.multiCalender,'multmiikmhuihuu...1111')
        this.multiCalender = false;
      } else {
        // console.log(this.multiCalender,'multmiikmhuihuu...2222')

        this.multiCalender = true;
      }
    },

    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        // let pluginWhats = ""
        // pluginWhats = getConfigData.payload.portal_configuration.menus.enabled.plugins
        // if (pluginWhats) {
        //   this.pluginWhatspp = true
        //   this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp
        // }
        // else {
        //   this.pluginWhatspp = false
        // }
        this.subscribeApi = getConfigData.payload.portal_configuration.API_endpoints.subscriber_form
        this.autocompleteApi =  getConfigData.payload.portal_configuration.API_endpoints.autocomplete
      }
    },

    autoDate() {
      this.oneway.travelDate = [];
      let value1 = new Date();
      let value2 = new Date(new Date().setDate(value1.getDate() + 7));

      this.oneway.travelDate.push(value1, value2);

      if (this.oneway.travelDate == 2) {
        this.oneway.dedate = this.oneway.travelDate[0];
        this.oneway.redate = this.oneway.travelDate[1];
      }

      // console.log(this.oneway.travelDate, 'creaaadddddddd....')
    },
    getClassType() {
      return [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
        {
          text: this.$t("formsContents.options.premiumEconomy"),
          value: "Premium Economy",
          department: "Premium Economy Department",
        },
      ];
    },





// recentFromData() {
//     let result1 = JSON.parse(localStorage.getItem("recentFrom"));

//     if (!result1 || result1.length === 0) {
//         console.log("No recent search data found.");
//         return;
//     }

//     let result2 = result1.slice(0, 15);

//     let uniqueRoutes = result2.filter((item, index, self) =>
//     {
//       console.log(self,'selffff')
//         index === self.findIndex((t) => (
//             t.from === item.from && t.to === item.to
//         ))
//     }
//     );

//     this.RecentSearchData = uniqueRoutes;

//     console.log(this.RecentSearchData, "Unique RecentSearchData");
// }

  },

  // AutoComplete Functions End
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.classTypeitems = this.getClassType();
      },
      deep: true,
    },

    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },

    "oneway.travelDate"(value) {
      if (value && value.length <= 2) {
        this.oneway.dedate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      } else {
        this.oneway.travelDate = null;
      }
    },

    "oneway.dedate"(value) {
      if (value) {
        this.focusEvent3 = false;
      } else {
        this.oneway.dedate = null;
      }
    },
  },

  // AutoComplete Functions
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.carouselData[this.currentSlide].src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'background-image 0.8s ease-in-out'
      };
    },
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },
  // AutoComplete Functions End

  created() {
    localStorage.removeItem("delete_Id");
    this.getConfig();
    // this.autoDate();
    // this.autocompletJson();
    window.addEventListener("resize", this.handleResize);
  },

  mounted() {
    // this.fetchData();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.body.addEventListener("click", this.handleOutsideClick);
    this.recentFromData();
    this.startAutoplay();
     if (this.carouselData.length > 1) {
      this.startAutoplay();
    }
    // this.generateRandomAlphabets()

    //
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
  },
};
</script>

<style scoped>
@import "@/assets/style.css";
>>> .v-selection-control-group {
  display: flex;
  flex-direction: row !important;
}
.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
  /* left: 0px;
  top: 24px; */
}
.p-error-from {
  font-size: 13px;
  color: red;
  position: absolute;
  bottom: -16px;
}

.p-error-to {
  font-size: 13px;
  color: red;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  display: block;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.recentform{
  min-height: auto;
  max-height: 366px;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding: 8px 0; */
}

/* .p-float-label {
   width: 100%;
   font-size: 16px;
   color: #242424;
   font-weight: 600;
 } */

.p-float-label label {
  position: relative;
  pointer-events: none;
  top: -26px;
  left: -50px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: #808080b3;
  font-size: 14px;
  font-weight: 500;
}
.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.formbuttons {
  background-color: #26225e;
}

.labelTop p {
  font-size: 13px;
  margin: 0;
  text-align: left;
  color: black;
  /* color: #3c0ec2; */
  font-weight: 600;
  padding-left: 13px;
}

.p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
}

.p-float-label .p-inputwrapper-filled ~ label {
  display: none !important;
}
>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}
.p-component * {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}
.input-styles {
  /* padding: 6px 0px 6px 8px; */
  /* background: #cecece2e; */
  height: 26px;
  border-radius: 2rem !important;
  padding-right: 12px;
  width: 100%;
  margin-right: 4px;
  padding-left: 8px;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}
>>> .p-autocomplete-panel .p-component .p-ripple-disabled ul {
  list-style: none !important;
}
.p-float-label label {
  left: 15px;
}
.p-float-label .fromLabel {
  left: -10px;
}
>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}
>>> .p-autocomplete .p-autocomplete-dropdown {
  display: none !important;
}
>>> .p-autocomplete .p-component .p-inputwrapper {
  top: -9px;
}
>>> .dp__input_icon {
  display: none;
}
/* >>> .dp__clear_icon {
  display: none;
} */
>>> .p-autocomplete-loader {
  position: relative;
  top: 80%;
}
.date .disable {
  opacity: 0.3 !important;
  /* padding-left:13px; */
}
>>> .economy .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

/* >>> .economy .select-1 .v-input__control {
  height: 34px;
} */

>>> .economy .select-1 .v-field__input {
  /* padding-top: 2; */
  align-items: center;
  padding: 0px;
  min-height: 0px;
}
>>> .economy .select-1 .v-input {
  height: 30px;
}

>>> .economy .select-1 .v-field__append-inner {
  padding-top: 0;
}
>>> .economy .v-field--variant-filled .v-field__overlay {
  background-color: transparent;
}
>>> .economy .v-field--variant-filled .v-field__outline::before {
  border-color: transparent !important;
}
.pax-fonts span {
  font-size: 15px;
  font-weight: 600;
}
>>> .multiDate .dp__input {
  background-color: transparent !important;
  border: none !important;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #6a2e4d; */
  color: #26225e;
  /* padding: 20px 20px; */
  height: 35px;
  font-family: "Barlow", sans-serif;
}
>>> .input-styles-2 .v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: grey !important;
}
.input-styles-2 {
  box-shadow: none;
  padding: 3px 5px 8px 12px;
  border: 1px solid #0000003d;
  height: 30px !important;
  color: black;
  background: white;
  border-radius: 8px;
}
>>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}
>>> .input-styles-2 .v-field__input {
  padding-top: 0px;
  min-height: 0px;
}
>>> .input-styles-2 .v-field .v-field__append-inner {
  padding-top: 0px;
}
>>> .input-styles-2 .v-field--variant-underlined .v-field__outline::before {
  border-color: transparent !important;
  border-style: none !important;
}
>>> .v-field--variant-underlined .v-field__outline::before {
  border-color: transparent !important;
  border-style: none !important;
}
>>> .p-autocomplete-option {
  display: block !important;
}
>>> .multiDate .dp__pointer {
  padding-left: 0px;
}
>>> .multiDate .dp__main {
  top: -5px;
}
.recent_style {
  position: absolute;
  background: white;
  width: 340px;
  margin: 12px 12px;
  border-radius: 20px;
  z-index: 5;
  /* border: 1px solid gray; */
}
.adt-chd {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px !important;
  font-weight: 500 !important;

  height: 35px;
  font-family: "Barlow", sans-serif;
}
.re-fon {
  font-size: 13px;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}
>>> .view-book .v-input__control {
  display: flex;
  grid-area: control;
  height: 45px;
}
>>>.view-book .v-field{
  --v-field-input-padding-top:0px !important;
  --v-field-input-padding-bottom:0px !important;
}
>>>.view-book .v-field__input{
  min-height: 50px !important;
}
.inter-input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #6a2e4d; */
  color: #26225e;
  /* padding: 20px 20px; */
  height: 25px;
  font-family: "Barlow", sans-serif;
}
.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.flight-search .pos-swap:hover {
  background-color: #ffc104;
  color: #343534;
}

@media screen and (max-width: 992px) {
  .flight-search .pos-swap {
    right: 0;
    top: 2.5rem;
  }
}
.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 13px;
  font-weight: 500;
}
>>> .select-option .v-input {
  --v-input-chips-margin-top: 0px !important;
}
.booking-form .form-grp {
  top: -3px;
}
>>> .roundFromTime .dp__input_wrap {
  top: -7px;
  left: -5px;
}
>>> .roundToTime .dp__input_wrap {
  top: -7px;
  left: -5px;
}
.labelTop {
  position: relative;
  top: 3px;
}
.resu-form:hover {
  background: #e3e1df;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .blog-area .row .col-53 {
    width: 100%;
    flex: 0 0 100%;
  }
  .blog-area .row .col-47 {
    width: 100%;
    flex: 0 0 100%;
  }
  .blog-content .title {
    font-size: 20px;
  }
  .blog-content {
    padding: 25px;
  }
  .destination-content ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .flight-offer-item.offer-item-two .flight-offer-thumb img {
    width: 100%;
  }
  .fly-next-thumb img {
    width: 100%;
  }
  .v-selection-control--density-default {
    --v-selection-control-size: 35px;
  }
  .booking-area .tab-content-wrap {
    width: 100%;
  }
  /* .booking-form {
    height: 290px;
  } */
  .booking-wrap .nav-tabs .nav-item {
    width: 100%;
  }
  .booking-form .form-grp {
    top: -13px;
  }
  .mobileForm,
  .multiData {
    height: auto;
  }
  .booking-form .form-grp.economy {
    padding: 0px 16px;
    height: 55px;
  }
  .booking-form .form-grp .exchange-icon {
    display: none;
  }
  .roundLabel .p-float-label label {
    left: -8px;
  }
  >>> .v-input__details {
    display: none;
  }
  >>> .multiData {
    border-bottom: 1px solid #a4a0a0;
  }
  .roundDataTo {
    left: -25px;
  }
  .booking-form ul li:nth-child(2) .form-grp input{
    padding:0px !important;
  }
}

.va-back:hover {
  background-color: lightblue;
}

.va-content {
  font-size: 13px;
  font-weight: 500;
  text-align: start;
}
>>> .dp__pointer {
  padding-left: 0px !important;
}
.true-btn {
  margin-bottom: 0;
  background: #ffffff;
  border: none;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  justify-content: center;
  transition: 0.3s linear;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
}
.false-btn {
  margin-bottom: 0;
  background: black;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  justify-content: center;
  transition: 0.3s linear;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
}
>>>.v-overlay__content{
  min-width:0px !important;
}
>>>.select-1 .v-field__outline{
  display:none !important;
}
>>>.v-menu .v-overlay__content{
  min-width:0px !important;
}
>>>.dp__input_icon_pad{
  padding-left:0px !important;
}
>>>.dp__input_icons{
  padding: 6px 12px !important;
}
.v-menu > .v-overlay__content{
  min-width:0px !important;
}
>>>.v-list{
  padding:0px !important;
}
.wrap-text {
  white-space: normal; /* Allow text to break onto the next line */
  word-wrap: break-word; /* Break long words if necessary */
  overflow-wrap: break-word; /* Ensures the word breaks in all browsers */
  max-width: 100%; /* Optional: you can define a width to control wrapping */
}
>>>.select-2 .v-input__control {
  border-bottom: none !important; /* Remove the underline */
}

>>>.select-2 .v-input__control:focus-within {
  border-bottom: none !important; /* Prevent underline on focus */
}

>>>.select-2 .v-field__outline {
  display: none !important;
}
.v-overlay__content .v-list{
  padding:0 !important;
}
</style>
